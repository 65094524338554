import React from "react";
import PropTypes from "prop-types";
import {alert} from "../../utils/sweetAlert";

export default class SmallBox extends React.Component {
  static propTypes = {
    color: PropTypes.oneOf(["info", "success", "warning", "danger"]),
    icon: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    isPrecentage: PropTypes.bool,
    moreInfo: PropTypes.string,
    moreInfoTo: PropTypes.string,
    loading: PropTypes.bool
  };

  static defaultProps = {
    color: "info",
    icon: "far fa-thumbs-up",
    text: "Messages",
    value: 1337,
    isPrecentage: undefined,
    moreInfo: "More Info",
    moreInfoTo: undefined,
    loading: undefined
  };

  render() {
    const {
      color,
      icon,
      text,
      value,
      isPrecentage,
      moreInfo,
      moreInfoTo, // eslint-disable-line no-unused-vars
      loading,
      push // eslint-disable-line no-unused-vars
    } = this.props;

    return (
        <div className={`small-box bg-${color}`}>
          <div className="inner">
            <h3>
              {value} {isPrecentage && <sup style={{fontSize: "20px"}}>%</sup>}
            </h3>
            <p>{text}</p>
          </div>
          <div className="icon">
            <i className={icon}/>
          </div>
          <span
              onClick={() => {
                // TODO
                // push(moreInfoTo);
                alert("warning", "not-implemented");
              }}
              className="small-box-footer"
              style={{cursor: "pointer"}}
          >
            {moreInfo} <i className="fas fa-arrow-circle-right"/>
          </span>
          {loading && (
              <div className="overlay">
                <i className="fas fa-sync-alt fa-spin"/>
              </div>
          )}
        </div>
    );
  }
}
