import React, {Component} from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Page from "../../components/Page";
import Navigation from "../../components/Navigation";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import Card from "../../components/Card/Card";
import Calendar from "../../components/Calendar";

import Row from "../../components/bootstrap/Row";
import Container from "../../components/bootstrap/Content";

import Input from "../../components/elements/Input";
import CurrencySelect from "../../components/elements/CurrencySelect";
import DragAndDropElements from "../../components/elements/DragAndDropElements";

import {EColors, EInput} from "../../constants";

import {alert} from "../../utils/sweetAlert";
import {isPriceValid, isTimeValid} from "../../utils/inputUtils";

const MySwal = withReactContent(sweetalert);

class ContractPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    currencies: PropTypes.array.isRequired
  };

  state = {
    isAddClicked: false,
    isEditClicked: false,
    isDeleteClicked: false,
    showEditCard: false,
    isValidStartTime: null,
    isValidPrice: null
  };

  componentDidUpdate = prevProps => {
    const {isAddClicked} = this.state;
    const {userId, getContracts} = this.props;

    if (
        isAddClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData &&
        this.props.apiResponse === true
    ) {
      this.setState({isAddClicked: false});
      this.props.resetApiResponse();
      getContracts(userId);
    }
  };

  handleOnClickAdd = () => {
    const {
      t,
      putCurrency,
      postCurrency,
      userId,
      currency_id,
      code,
      name
    } = this.props;
    const {showEditCard, isValidCode, isValidName} = this.state;
    const isFormValid = isValidCode && isValidName;

    if (isFormValid) {
      const transferData = {
        user_id: userId,
        name,
        code
      };

      if (showEditCard) {
        transferData.currency_id = currency_id;
        putCurrency(transferData);
      } else {
        postCurrency(transferData);
      }

      this.setState({isAddClicked: true});
    } else {
      alert("warning", t("form-not-valid"));
    }
  };

  handleOnClickDelete = currencyId => {
    const {t, deleteCurrency} = this.props;
    MySwal.fire({
      title: t("are-you-sure"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: EColors.DANGER,
      cancelButtonColor: EColors.SECONDARY,
      confirmButtonText: t("yes"),
      cancelButtonText: t("cancel")
    }).then(result => {
      if (result.value) {
        this.setState({isDeleteClicked: true});
        deleteCurrency(currencyId);
        MySwal.fire({
          title: t("deleted"),
          type: "success",
          confirmButtonColor: EColors.SECONDARY,
          confirmButtonText: t("close")
        });
      }
    });
  };

  setDefaultValue = (value, type) => {
    const {setContractInfo} = this.props;

    if (type === "currency") {
      setContractInfo({currency_id: value});
    } else if (type === "employer") {
      setContractInfo({employer_id: value});
    }
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;
    const {setContractInfo} = this.props;

    if (name === EInput.CONTRACT_START_TIME.name) {
      setContractInfo({start_time: value});
    } else if (name === EInput.CONTRACT_PRICE.name) {
      setContractInfo({price: value});
    } else if (name === EInput.CURRENCY.name) {
      setContractInfo({currency_id: value});
    }
  };

  onEventDrop = (employerId, currencyId, date, startTime, price) => {
    const {userId, postContract} = this.props;

    const transferData = {
      user_id: userId,
      employer_id: employerId,
      currency_id: currencyId,
      date,
      start_time: startTime,
      price
    };

    this.setState({isAddClicked: true});
    postContract(transferData);
  };

  render() {
    const {showEditCard, isValidPrice, isValidStartTime} = this.state;

    const {
      t,
      isLoading,
      currencies,
      employers,
      contracts,
      currency,
      startTime,
      price
    } = this.props;

    return (
        <React.Fragment>
          <Navigation t={t}/>
          <Page>
            <PageHeader
                title={t("contracts")}
                breadcrumbs={[{title: t("home"), to: "/home"}]}
            />
            <div className="content">
              <Container fluid>
                <Row>
                  <div className="col-12 col-sm-3">
                    <Card
                        title={showEditCard ? t("edit") : t("create-new")}
                        loading={isLoading}
                    >
                      <Input
                          id={EInput.CONTRACT_START_TIME.id}
                          name={EInput.CONTRACT_START_TIME.name}
                          icon={EInput.CONTRACT_START_TIME.icon}
                          type={EInput.CONTRACT_START_TIME.type}
                          step={EInput.CONTRACT_START_TIME.step}
                          label={t(EInput.CONTRACT_START_TIME.label)}
                          placeholder={t(
                              EInput.CONTRACT_START_TIME.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidStartTime: isTimeValid(startTime)
                            });
                          }}
                          required
                          value={startTime}
                          isValid={isValidStartTime}
                      />
                      <Input
                          id={EInput.CONTRACT_PRICE.id}
                          name={EInput.CONTRACT_PRICE.name}
                          icon={EInput.CONTRACT_PRICE.icon}
                          type={EInput.CONTRACT_PRICE.type}
                          label={t(EInput.CONTRACT_PRICE.label)}
                          placeholder={t(EInput.CONTRACT_PRICE.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidPrice: isPriceValid(price)
                            });
                          }}
                          required
                          value={price}
                          isValid={isValidPrice}
                      />
                      <CurrencySelect
                          id={EInput.CURRENCY.id}
                          name={EInput.CURRENCY.name}
                          icon={EInput.CURRENCY.icon}
                          label={t(EInput.CURRENCY.label)}
                          onChange={this.handleInputChange}
                          setDefaultValue={this.setDefaultValue}
                          currencies={currencies}
                          selectedValue={currency}
                      />
                      <DragAndDropElements
                          icon="fas fa-users primary-icon"
                          label={t("employers")}
                          optionalText={t("drag-and-drop")}
                          employers={employers}
                      />
                    </Card>
                  </div>
                  <div className="col-12 col-sm-9">
                    <Calendar
                        title={t("calendar")}
                        t={t}
                        onDateClick={this.onDateClick}
                        onEventClick={this.onEventClick}
                        onEventDrop={this.onEventDrop}
                        data={contracts}
                        loading={isLoading}
                    />
                  </div>
                </Row>
              </Container>
            </div>
          </Page>
          <PageFooter/>
        </React.Fragment>
    );
  }
}

export default translate("common")(ContractPage);
