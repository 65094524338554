import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "../SidebarLink";
import SidebarDropdown from "../SidebarDropdown";
import SidebarBrand from "../SidebarBrand";
import SidebarHeader from "../SidebarHeader";

import {ELanguages, ENavigation} from "../../constants";

export default class Sidebar extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t} = this.props;
    const userLocation = window.location.pathname;

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <SidebarBrand/>

          <div className="sidebar">
            <nav className="mt-2">
              <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-card-widget="treeview"
                  role="menu"
                  data-accordion="false"
              >
                <SidebarHeader title={t("dashboard")}/>
                <SidebarLink
                    title={t(ENavigation.HOME.title)}
                    icon={ENavigation.HOME.icon}
                    to={ENavigation.HOME.to}
                    isActive={ENavigation.HOME.to === userLocation}
                />
                <SidebarHeader title={t("currencies")}/>
                <SidebarLink
                    title={t(ENavigation.CURRENCIES.title)}
                    icon={ENavigation.CURRENCIES.icon}
                    to={ENavigation.CURRENCIES.to}
                    isActive={ENavigation.CURRENCIES.to === userLocation}
                />
                <SidebarHeader title={t("employers")}/>
                <SidebarLink
                    title={t(ENavigation.EMPLOYERS.title)}
                    icon={ENavigation.EMPLOYERS.icon}
                    to={ENavigation.EMPLOYERS.to}
                    isActive={ENavigation.EMPLOYERS.to === userLocation}
                />
                <SidebarHeader title={t("contracts")}/>
                <SidebarLink
                    title={t(ENavigation.CONTRACTS.title)}
                    icon={ENavigation.CONTRACTS.icon}
                    to={ENavigation.CONTRACTS.to}
                    isActive={ENavigation.CONTRACTS.to === userLocation}
                />
                <SidebarHeader title={t("languages")}/>
                <SidebarDropdown title={t("languages")} icon="fas fa-language">
                  <SidebarLink
                      title={t(ELanguages.CROATIAN.title)}
                      icon={ELanguages.CROATIAN.icon}
                      isLanguageIcon
                      onClickAction={ELanguages.CROATIAN.onClick}
                  />
                  <SidebarLink
                      title={t(ELanguages.ENGLISH.title)}
                      icon={ELanguages.ENGLISH.icon}
                      isLanguageIcon
                      onClickAction={ELanguages.ENGLISH.onClick}
                  />
                  <SidebarLink
                      title={t(ELanguages.RUSSIAN.title)}
                      icon={ELanguages.RUSSIAN.icon}
                      isLanguageIcon
                      onClickAction={ELanguages.RUSSIAN.onClick}
                  />
                  <SidebarLink
                      title={t(ELanguages.GERMAN.title)}
                      icon={ELanguages.GERMAN.icon}
                      isLanguageIcon
                      onClickAction={ELanguages.GERMAN.onClick}
                  />
                </SidebarDropdown>
              </ul>
            </nav>
          </div>
        </aside>
    );
  }
}
