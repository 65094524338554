import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import {generateRadomString} from "../../../utils/generatorUtils";

export default class TableHeader extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    title: ["ID", "Number", "Level"]
  };

  componentDidMount = () => {
    var table = $("table");

    $(".sortable").each(function () {
      var th = $(this),
          thIndex = th.index(),
          inverse = false;

      th.click(function () {
        table
        .find("td")
        .filter(function () {
          return $(this).index() === thIndex;
        })
        .sortElements(
            function (a, b) {
              return $.text([a]) > $.text([b])
                  ? inverse
                      ? -1
                      : 1
                  : inverse
                      ? 1
                      : -1;
            },
            function () {
              // parentNode is the element we want to move
              return this.parentNode;
            }
        );
        inverse = !inverse;
      });
    });
  };

  componentDidUpdate = () => {
    var table = $("table");

    $(".sortable").each(function () {
      var th = $(this),
          thIndex = th.index(),
          inverse = false;

      th.click(function () {
        table
        .find("td")
        .filter(function () {
          return $(this).index() === thIndex;
        })
        .sortElements(
            function (a, b) {
              return $.text([a]) > $.text([b])
                  ? inverse
                      ? -1
                      : 1
                  : inverse
                      ? 1
                      : -1;
            },
            function () {
              // parentNode is the element we want to move
              return this.parentNode;
            }
        );
        inverse = !inverse;
      });
    });
  };

  generateTableHeader = data => {
    const tableHeader = [];

    data.forEach(element => {
      tableHeader.push(
          <th className="sortable text-center" key={generateRadomString()}>
            <i className="fas fa-sort"/>
            &nbsp;
            {element}
          </th>
      );
    });

    return tableHeader;
  };

  render() {
    const {data} = this.props;

    return (
        <thead>
        <tr style={{cursor: "pointer"}}>{this.generateTableHeader(data)}</tr>
        </thead>
    );
  }
}
