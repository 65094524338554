import React from "react";
import PropTypes from "prop-types";

import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

export default class Navigation extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t} = this.props;
    return (
        <React.Fragment>
          <Navbar t={t}/>
          <Sidebar t={t}/>
        </React.Fragment>
    );
  }
}
