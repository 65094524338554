import React from "react";
import PropTypes from "prop-types";

export default class Label extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    optionalText: PropTypes.string
  };

  static defaultProps = {
    id: undefined,
    text: undefined,
    icon: undefined,
    optionalText: undefined
  };

  render() {
    const {id, text, icon, optionalText} = this.props;

    return (
        <label htmlFor={id}>
          {icon && (
              <span>
            <i className={icon}/>
                &nbsp;
          </span>
          )}
          {text}
          {optionalText && (
              <small
                  className="text-mute">&nbsp;&minus;&nbsp;{optionalText}</small>
          )}
        </label>
    );
  }
}
