import React from "react";
import PropTypes from "prop-types";

export default class SidebarBrand extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    imageUrl: PropTypes.string
  };

  static defaultProps = {
    imageUrl: "dist/img/workbook-web-logo.png"
  };

  render() {
    const {push, imageUrl} = this.props;

    return (
        <span
            onClick={() => {
              push("/home");
            }}
            className="brand-link"
            style={{color: "rgba(255,255,255,.8)", cursor: "pointer"}}
        >
          <img
              src={imageUrl}
              alt="AdminLTE Logo"
              className="brand-image elevation-3"
              style={{opacity: ".8"}}
          />
          <span className="brand-text font-weight-light">
          <b>Work</b>Book
        </span>
        </span>
    );
  }
}
