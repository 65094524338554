import currenciesTypes from "./types";

export const resetCurrencies = () => ({
  type: currenciesTypes.RESET_CURRENCIES
});

export const resetCurrency = () => ({
  type: currenciesTypes.RESET_CURRENCY
});

export const setCurrencyInfo = newInfo => ({
  type: currenciesTypes.SET_CURRENCY_INFO,
  newInfo
});

export const getCurrencyStart = () => ({
  type: currenciesTypes.GET_CURRENCY_START
});

export const getCurrency = currencyId => ({
  type: currenciesTypes.GET_CURRENCY,
  currencyId
});

export const getCurrencySuccess = currency => ({
  type: currenciesTypes.GET_CURRENCY_SUCCESS,
  currency
});

export const getCurrencyFailed = () => ({
  type: currenciesTypes.GET_CURRENCY_FAILED
});

export const getCurrenciesStart = () => ({
  type: currenciesTypes.GET_CURRENCIES_START
});

export const getCurrencies = userId => ({
  type: currenciesTypes.GET_CURRENCIES,
  userId
});

export const getCurrenciesSuccess = currencies => ({
  type: currenciesTypes.GET_CURRENCIES_SUCCESS,
  currencies
});

export const getCurrenciesFailed = () => ({
  type: currenciesTypes.GET_CURRENCIES_FAILED
});

export const postCurrencyStart = () => ({
  type: currenciesTypes.POST_CURRENCY_START
});

export const postCurrency = dataRequest => ({
  type: currenciesTypes.POST_CURRENCY,
  dataRequest
});

export const postCurrencySuccess = apiResponse => ({
  type: currenciesTypes.POST_CURRENCY_SUCCESS,
  apiResponse
});

export const postCurrencyFailed = () => ({
  type: currenciesTypes.POST_CURRENCY_FAILED
});

export const deleteCurrencyStart = () => ({
  type: currenciesTypes.DELETE_CURRENCY_START
});

export const deleteCurrency = currencyId => ({
  type: currenciesTypes.DELETE_CURRENCY,
  currencyId
});

export const deleteCurrencySuccess = apiResponse => ({
  type: currenciesTypes.DELETE_CURRENCY_SUCCESS,
  apiResponse
});

export const deleteCurrencyFailed = () => ({
  type: currenciesTypes.DELETE_CURRENCY_FAILED
});

export const putCurrencyStart = () => ({
  type: currenciesTypes.PUT_CURRENCY_START
});

export const putCurrency = dataRequest => ({
  type: currenciesTypes.PUT_CURRENCY,
  dataRequest
});

export const putCurrencySuccess = apiResponse => ({
  type: currenciesTypes.PUT_CURRENCY_SUCCESS,
  apiResponse
});

export const putCurrencyFailed = () => ({
  type: currenciesTypes.PUT_CURRENCY_FAILED
});
