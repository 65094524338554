import React from "react";
import PropTypes from "prop-types";

import Container from "../bootstrap/Content";
import ALink from "../elements/ALink";

import {generateRadomString} from "../../utils/generatorUtils";

export default class PageHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired
        })
    ),
    push: PropTypes.func
  };

  static defaultProps = {
    title: "Project D",
    breadcrumbs: [
      {
        title: "Home",
        to: "/home"
      }
    ]
  };

  generateBreadcrumbs = breadcrumbs => {
    const divBreadcrumbs = [];

    breadcrumbs.forEach(element => {
      divBreadcrumbs.push(
          <li key={generateRadomString()} className="breadcrumb-item">
            <ALink to={element.to} title={element.title}/>
          </li>
      );
    });

    return divBreadcrumbs;
  };

  render() {
    const {title, breadcrumbs} = this.props;

    return (
        <div className="content-header">
          <Container fluid>
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{title}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {this.generateBreadcrumbs(breadcrumbs)}
                  <li className="breadcrumb-item active">{title}</li>
                </ol>
              </div>
            </div>
          </Container>
        </div>
    );
  }
}
