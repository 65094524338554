import {call, put, takeLatest} from "redux-saga/effects";
import {putRequest} from "../../utils/axios-wrapper";
import employerTypes from "../types";

import {
  putEmployerFailed,
  putEmployerStart,
  putEmployerSuccess
} from "../actions";

export const callToPutEmployer = data =>
    putRequest({
      route: `/employers/${data.employer_id}`,
      data
    });

export function* putEmployer({dataRequest}) {
  try {
    yield put(putEmployerStart());
    const apiResponse = yield call(callToPutEmployer, dataRequest);
    yield put(putEmployerSuccess(apiResponse.data));
  } catch (error) {
    yield put(putEmployerFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* putContractSaga() {
  yield takeLatest(employerTypes.PUT_EMPLOYER, putEmployer);
}
