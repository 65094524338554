import {call} from "redux-saga/effects";
import configureStoreBoilerplate from "./configure-store-boilerplate";

import userReducer, {postLoginSaga, postRegisterSaga} from "../modules/user";

import unsubmittedContractsReducer, {getUnsubmittedContractsSaga} from "../modules/unsubmitted-contracts";

import contractsReducer, {
  deleteContractSaga,
  getContractSaga,
  getContractsSaga,
  postContractSaga,
  putContractSaga,
  submitContractSaga
} from "../modules/contracts";

import employersReducer, {
  deleteEmployerSaga,
  getEmployerSaga,
  getEmployersSaga,
  postEmployerSaga,
  putEmployerSaga
} from "../modules/employers";

import currenciesReducer, {
  deleteCurrencySaga,
  getCurrenciesSaga,
  getCurrencySaga,
  postCurrencySaga,
  putCurrencySaga
} from "../modules/currencies";

import reportsReducer, {getIncomeForMonthSaga} from "../modules/reports";

export default (history, initialState = {}) =>
    configureStoreBoilerplate(
        {
          user: userReducer,
          unsubmittedContracts: unsubmittedContractsReducer,
          contracts: contractsReducer,
          currencies: currenciesReducer,
          employers: employersReducer,
          reports: reportsReducer
        },
        [
          call(postRegisterSaga),
          call(postLoginSaga),
          call(getUnsubmittedContractsSaga),
          call(submitContractSaga),
          call(getCurrencySaga),
          call(getCurrenciesSaga),
          call(getContractSaga),
          call(getContractsSaga),
          call(putContractSaga),
          call(postContractSaga),
          call(deleteContractSaga),
          call(postCurrencySaga),
          call(getEmployerSaga),
          call(getEmployersSaga),
          call(putEmployerSaga),
          call(postEmployerSaga),
          call(deleteEmployerSaga),
          call(deleteCurrencySaga),
          call(putCurrencySaga),
          call(getIncomeForMonthSaga)
        ],
        history,
        initialState
    );
