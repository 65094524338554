export default {
  RESET_CONTRACTS: "contracts/RESET_CONTRACTS",
  RESET_CONTRACT: "contracts/RESET_CONTRACT",
  RESET_API_RESPONSE: "contracts/RESET_API_RESPONSE",

  SET_CONTRACT_INFO: "contracts/SET_CONTRACT_INFO",

  PUT_SUBMIT_CONTRACT_START: "contracts/PUT_SUBMIT_CONTRACT_START",
  PUT_SUBMIT_CONTRACT: "contracts/PUT_SUBMIT_CONTRACT",
  PUT_SUBMIT_CONTRACT_SUCCESS: "contracts/PUT_SUBMIT_CONTRACT_SUCCESS",
  PUT_SUBMIT_CONTRACT_FAILED: "contracts/PUT_SUBMIT_CONTRACT_FAILED",

  GET_CONTRACTS_START: "contracts/GET_CONTRACTS_START",
  GET_CONTRACTS: "contracts/GET_CONTRACTS",
  GET_CONTRACTS_SUCCESS: "contracts/GET_CONTRACTS_SUCCESS",
  GET_CONTRACTS_FAILED: "contracts/GET_CONTRACTS_FAILED",

  GET_CONTRACT_START: "contracts/GET_CONTRACT_START",
  GET_CONTRACT: "contracts/GET_CONTRACT",
  GET_CONTRACT_SUCCESS: "contracts/GET_CONTRACT_SUCCESS",
  GET_CONTRACT_FAILED: "contracts/GET_CONTRACT_FAILED",

  POST_CONTRACT_START: "contracts/POST_CONTRACT_START",
  POST_CONTRACT: "contracts/POST_CONTRACT",
  POST_CONTRACT_SUCCESS: "contracts/POST_CONTRACT_SUCCESS",
  POST_CONTRACT_FAILED: "contracts/POST_CONTRACT_FAILED",

  PUT_CONTRACT_START: "contracts/PUT_CONTRACT_START",
  PUT_CONTRACT: "contracts/PUT_CONTRACT",
  PUT_CONTRACT_SUCCESS: "contracts/PUT_CONTRACT_SUCCESS",
  PUT_CONTRACT_FAILED: "contracts/PUT_CONTRACT_FAILED",

  DELETE_CONTRACT_START: "contracts/DELETE_CONTRACT_START",
  DELETE_CONTRACT: "contracts/DELETE_CONTRACT",
  DELETE_CONTRACT_SUCCESS: "contracts/DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILED: "contracts/DELETE_CONTRACT_FAILED"
};
