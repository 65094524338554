export default {
  RESET_CURRENCIES: "currencies/RESET_CURRENCIES",
  RESET_CURRENCY: "currencies/RESET_CURRENCY",

  SET_CURRENCY_INFO: "currencies/SET_CURRENCY_INFO",

  GET_CURRENCY_START: "currencies/GET_CURRENCY_START",
  GET_CURRENCY: "currencies/GET_CURRENCY",
  GET_CURRENCY_SUCCESS: "currencies/GET_CURRENCY_SUCCESS",
  GET_CURRENCY_FAILED: "currencies/GET_CURRENCY_FAILED",

  GET_CURRENCIES_START: "currencies/GET_CURRENCIES_START",
  GET_CURRENCIES: "currencies/GET_CURRENCIES",
  GET_CURRENCIES_SUCCESS: "currencies/GET_CURRENCIES_SUCCESS",
  GET_CURRENCIES_FAILED: "currencies/GET_CURRENCIES_FAILED",

  POST_CURRENCY_START: "currencies/POST_CURRENCY_START",
  POST_CURRENCY: "currencies/POST_CURRENCY",
  POST_CURRENCY_SUCCESS: "currencies/POST_CURRENCY_SUCCESS",
  POST_CURRENCY_FAILED: "currencies/POST_CURRENCY_FAILED",

  DELETE_CURRENCY_START: "currencies/DELETE_CURRENCY_START",
  DELETE_CURRENCY: "currencies/DELETE_CURRENCY",
  DELETE_CURRENCY_SUCCESS: "currencies/DELETE_CURRENCY_SUCCESS",
  DELETE_CURRENCY_FAILED: "currencies/DELETE_CURRENCY_FAILED",

  PUT_CURRENCY_START: "currencies/PUT_CURRENCY_START",
  PUT_CURRENCY: "currencies/PUT_CURRENCY",
  PUT_CURRENCY_SUCCESS: "currencies/PUT_CURRENCY_SUCCESS",
  PUT_CURRENCY_FAILED: "currencies/PUT_CURRENCY_FAILED"
};
