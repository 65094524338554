import React from "react";
import PropTypes from "prop-types";

export default class NavbarLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool
  };

  render() {
    const {push, to, title, isActive} = this.props;

    return (
        <li className="nav-item d-none d-sm-inline-block">
          <span
              onClick={() => push(to)}
              className={`nav-link ${isActive ? "active" : ""}`}
              style={{cursor: "pointer"}}
          >
            {title}
          </span>
        </li>
    );
  }
}
