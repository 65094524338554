import {call, put, takeLatest} from "redux-saga/effects";
import {deleteRequest} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  deleteContractFailed,
  deleteContractStart,
  deleteContractSuccess
} from "../actions";

export const callToDeleteContract = contractId =>
    deleteRequest({
      route: `/contract/${contractId}`
    });

export function* deleteContract({contractId}) {
  try {
    yield put(deleteContractStart());
    const apiResponse = yield call(callToDeleteContract, contractId);
    yield put(deleteContractSuccess(apiResponse.data));
  } catch (error) {
    yield put(deleteContractFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* submitContractSaga() {
  yield takeLatest(contractTypes.DELETE_CONTRACT, deleteContract);
}
