import {call, put, takeLatest} from "redux-saga/effects";
import {post} from "../../utils/axios-wrapper";
import userTypes from "../types";
import {
  postRegisterFail,
  postRegisterStart,
  postRegisterSuccess
} from "../actions";

export const callToPostRegister = data =>
    post({
      route: `/register`,
      data
    });

export function* postRegister({register}) {
  try {
    yield put(postRegisterStart());
    const apiResponse = yield call(callToPostRegister, register);
    yield put(postRegisterSuccess(apiResponse.data));
  } catch (error) {
    yield put(postRegisterFail(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postRegisterSaga() {
  yield takeLatest(userTypes.POST_REGISTER, postRegister);
}
