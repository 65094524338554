import {call, put, takeLatest} from "redux-saga/effects";
import {putRequest} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  putSubmitContractFailed,
  putSubmitContractStart,
  putSubmitContractSuccess
} from "../actions";

export const callToSubmitContract = (userId, dataRequest) =>
    putRequest({
      route: `/submit-contract/${userId}`,
      data: dataRequest
    });

export function* putSubmitContract({userId, dataRequest}) {
  try {
    yield put(putSubmitContractStart());
    const apiResponse = yield call(callToSubmitContract, userId, dataRequest);
    yield put(putSubmitContractSuccess(apiResponse.data));
  } catch (error) {
    yield put(putSubmitContractFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* submitContractSaga() {
  yield takeLatest(contractTypes.PUT_SUBMIT_CONTRACT, putSubmitContract);
}
