import sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(sweetalert);

// const possibleTypes = ["success", "error", "warning", "info", "question"];

export function alert(type, title) {
  return MySwal.fire({
    title: title,
    type: type,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000
  });
}
