import request from "axios";

const requestWrapper = (method, {route, data, headers}) => {
  const baseUrl = process.env.REACT_APP_API || "http://localhost:9501/api/v2"
  const url = baseUrl + route;

  console.debug(`Method: ${method} , URI: ${url}`);

  return request({
    method,
    url,
    data,
    json: true,
    headers: {
      ...headers,
      "content-type": "application/json",
      Authorization:
          "Basic d29ya2Jvb2stYXBpLWFwaXVzZXI6JGFwcjEkVHExWHNYbXMkVEVqZ2RWeVZzOS9RRWxaTklTaHV2MQ==",
      Accept: "application/json"
    }
  });
};

export const get = data =>
    typeof data === "string"
        ? requestWrapper("get", {route: data})
        : requestWrapper("get", data);
export const post = data => requestWrapper("post", data);
export const deleteRequest = data => requestWrapper("delete", data);
export const putRequest = data => requestWrapper("put", data);
export const patch = data => requestWrapper("patch", data);
