import contractTypes from "./types";

export const resetContracts = () => ({
  type: contractTypes.RESET_CONTRACTS
});

export const resetContract = () => ({
  type: contractTypes.RESET_CONTRACT
});

export const resetApiResponse = () => ({
  type: contractTypes.RESET_API_RESPONSE
});

export const setContractIdForSubmit = contractId => ({
  type: contractTypes.SET_CONTRACT_ID_FOR_SUBMIT,
  contractId
});

export const setContractInfo = newContractInfo => ({
  type: contractTypes.SET_CONTRACT_INFO,
  newContractInfo
});

export const putSubmitContractStart = () => ({
  type: contractTypes.PUT_SUBMIT_CONTRACT_START
});

export const putSubmitContract = dataRequest => ({
  type: contractTypes.PUT_SUBMIT_CONTRACT,
  dataRequest
});

export const putSubmitContractSuccess = apiResponse => ({
  type: contractTypes.PUT_SUBMIT_CONTRACT_SUCCESS,
  apiResponse
});

export const putSubmitContractFailed = () => ({
  type: contractTypes.PUT_SUBMIT_CONTRACT_FAILED
});

export const getContractStart = () => ({
  type: contractTypes.GET_CONTRACT_START
});

export const getContract = contractId => ({
  type: contractTypes.GET_CONTRACT,
  contractId
});

export const getContractSuccess = contract => ({
  type: contractTypes.GET_CONTRACT_SUCCESS,
  contract
});

export const getContractFailed = () => ({
  type: contractTypes.GET_CONTRACT_FAILED
});

export const getContractsStart = () => ({
  type: contractTypes.GET_CONTRACTS_START
});

export const getContracts = userId => ({
  type: contractTypes.GET_CONTRACTS,
  userId
});

export const getContractsSuccess = contracts => ({
  type: contractTypes.GET_CONTRACTS_SUCCESS,
  contracts
});

export const getContractsFailed = () => ({
  type: contractTypes.GET_CONTRACTS_FAILED
});

export const putContractStart = () => ({
  type: contractTypes.PUT_CONTRACT_START
});

export const putContract = dataRequest => ({
  type: contractTypes.PUT_CONTRACT,
  dataRequest
});

export const putContractSuccess = apiResponse => ({
  type: contractTypes.PUT_CONTRACT_SUCCESS,
  apiResponse
});

export const putContractFailed = () => ({
  type: contractTypes.PUT_CONTRACT_FAILED
});

export const postContractStart = () => ({
  type: contractTypes.POST_CONTRACT_START
});

export const postContract = dataRequest => ({
  type: contractTypes.POST_CONTRACT,
  dataRequest
});

export const postContractSuccess = apiResponse => ({
  type: contractTypes.POST_CONTRACT_SUCCESS,
  apiResponse
});

export const postContractFailed = () => ({
  type: contractTypes.POST_CONTRACT_FAILED
});

export const deleteContractStart = () => ({
  type: contractTypes.DELETE_CONTRACT_START
});

export const deleteContract = contractId => ({
  type: contractTypes.DELETE_CONTRACT,
  contractId
});

export const deleteContractSuccess = apiResponse => ({
  type: contractTypes.DELETE_CONTRACT_SUCCESS,
  apiResponse
});

export const deleteContractFailed = () => ({
  type: contractTypes.DELETE_CONTRACT_FAILED
});
