import reportTypes from "./types";

export const resetReports = () => ({
  type: reportTypes.RESET_REPORTS
});

export const getIncomeForMonthStart = () => ({
  type: reportTypes.GET_INCOME_FOR_MONTH_START
});

export const getIncomeForMonth = (userId, year, month) => ({
  type: reportTypes.GET_INCOME_FOR_MONTH,
  userId,
  year,
  month
});

export const getIncomeForMonthSuccess = reportIncome => ({
  type: reportTypes.GET_INCOME_FOR_MONTH_SUCCESS,
  reportIncome
});

export const getIncomeForMonthFailed = () => ({
  type: reportTypes.GET_INCOME_FOR_MONTH_FAILED
});

export const getNumberOfUnsubmittedContractsStart = () => ({
  type: reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_START
});

export const getNumberOfUnsubmittedContracts = userId => ({
  type: reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS,
  userId
});

export const getNumberOfUnsubmittedContractsSuccess = numberOfUnsubmittedContracts => ({
  type: reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_SUCCESS,
  numberOfUnsubmittedContracts
});

export const getNumberOfUnsubmittedContractsFailed = () => ({
  type: reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_FAILED
});
