import userTypes from "./types";

export const resetUser = () => ({
  type: userTypes.RESET_USER
});

export const resetLogin = () => ({
  type: userTypes.RESET_LOGIN
});

export const resetRegister = () => ({
  type: userTypes.RESET_REGISTER
});

export const setLoginInfo = newLoginInfo => ({
  type: userTypes.SET_LOGIN_INFO,
  newLoginInfo
});

export const setRegisterInfo = newRegisterInfo => ({
  type: userTypes.SET_REGISTER_INFO,
  newRegisterInfo
});

//  ----

export const postLogin = login => ({
  type: userTypes.POST_LOGIN,
  login
});

export const postLoginStart = () => ({
  type: userTypes.POST_LOGIN_START
});

export const postLoginFail = err => ({
  type: userTypes.POST_LOGIN_FAIL,
  err
});

export const postLoginSuccess = userdata => ({
  type: userTypes.POST_LOGIN_SUCCESS,
  userdata
});

//  ----

export const postRegister = register => ({
  type: userTypes.POST_REGISTER,
  register
});

export const postRegisterStart = () => ({
  type: userTypes.POST_REGISTER_START
});

export const postRegisterFail = err => ({
  type: userTypes.POST_REGISTER_FAIL,
  err
});

export const postRegisterSuccess = userdata => ({
  type: userTypes.POST_REGISTER_SUCCESS,
  userdata
});
