import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";
// Component
import ContractPage from "./component";

import {
  deleteContract,
  getContract,
  getContracts,
  postContract,
  putContract,
  resetApiResponse,
  resetContract,
  setContractInfo
} from "../../modules/contracts/actions";

const mapStateToProps = ({user, currencies, employers, contracts}) => ({
  hasFetchedData: contracts.hasFetchedData,
  isLoading: contracts.isLoading,
  apiResponse: contracts.apiResponse,

  userId: user.authUser.userId,

  currencies: currencies.currencies,
  employers: employers.employers,
  contracts: contracts.contracts,

  employer: contracts.contract.employer_id,
  currency: contracts.contract.currency_id,
  startTime: contracts.contract.start_time,
  price: contracts.contract.price,
  date: contracts.contract.date
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push,
          setContractInfo,
          resetContract,
          resetApiResponse,
          postContract,
          getContracts,
          getContract,
          putContract,
          deleteContract
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractPage);
