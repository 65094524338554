import React, {Component} from "react";
import {translate} from "react-i18next";

import Button from "../../components/elements/Button";
import Input from "../../components/elements/Input";
import ALink from "../../components/elements/ALink";
import Row from "../../components/bootstrap/Row";

import {EInput} from "../../constants";

import {alert} from "../../utils/sweetAlert";
import {
  isEmailValid,
  isNameValid,
  isPasswordValid,
  isUsernameValid
} from "../../utils/inputUtils";

class RegisterPage extends Component {
  state = {
    isButtonClicked: null,
    isValidFirstName: null,
    isValidLastName: null,
    isValidUsername: null,
    isValidEmail: null,
    isValidPassword: null,
    isValidRepeatPassword: null
  };

  componentDidUpdate = prevprops => {
    const {isButtonClicked} = this.state;
    const {t} = this.props;

    if (prevprops.hasError !== this.props.hasError && isButtonClicked) {
      alert("error", t("error-login"));
      this.props.resetRegister();
      this.setState({
        isButtonClicked: null,
        isValidFirstName: null,
        isValidLastName: null,
        isValidUsername: null,
        isValidEmail: null,
        isValidPassword: null,
        isValidRepeatPassword: null
      });
    }
  };

  validateRepeatPassword = value => {
    const {password} = this.props;
    const isValid = isPasswordValid(value) && password === value;
    this.setState({isValidRepeatPassword: isValid});
    return isValid;
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;
    const {setRegisterInfo} = this.props;

    if (name === EInput.FIRST_NAME.name) {
      setRegisterInfo({firstname: value});
    } else if (name === EInput.LAST_NAME.name) {
      setRegisterInfo({lastname: value});
    } else if (name === EInput.USERNAME.name) {
      setRegisterInfo({username: value});
    } else if (name === EInput.EMAIL.name) {
      setRegisterInfo({email: value});
    } else if (name === EInput.PASSWORD.name) {
      setRegisterInfo({password: value});
    } else if (name === EInput.REPEAT_PASSWORD.name) {
      setRegisterInfo({repeatpassword: value});
    }
  };

  handleRegister = () => {
    const {
      isValidFirstName,
      isValidLastName,
      isValidUsername,
      isValidEmail,
      isValidPassword,
      isValidRepeatPassword
    } = this.state;

    const {
      t,
      postRegister,
      firstname,
      lastname,
      username,
      email,
      password
    } = this.props;

    const isFormValid =
        isValidFirstName &&
        isValidLastName &&
        isValidUsername &&
        isValidEmail &&
        isValidPassword &&
        isValidRepeatPassword;

    if (isFormValid) {
      const transferData = {
        firstname,
        lastname,
        username,
        email,
        password
      };

      this.setState({isButtonClicked: true});
      postRegister(transferData);
    } else {
      alert("warning", t("form-not-valid"));
    }
  };

  render() {
    const {
      isValidFirstName,
      isValidLastName,
      isValidEmail,
      isValidUsername,
      isValidPassword,
      isValidRepeatPassword
    } = this.state;

    const {
      t,
      isLoading,
      username,
      password,
      repeatpassword,
      email,
      firstname,
      lastname
    } = this.props;

    return (
        <div className="register-page">
          <div className="register-box">
            <div className="register-logo">
              <b>Work</b>Book
            </div>
            <div className="card">
              <div className="card-body register-card-body">
                <p className="login-box-msg">{t("register-membership")}</p>
                <form>
                  <Input
                      id={EInput.FIRST_NAME.id}
                      name={EInput.FIRST_NAME.name}
                      type={EInput.FIRST_NAME.type}
                      placeholder={t(EInput.FIRST_NAME.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidFirstName: isNameValid(firstname)
                        });
                      }}
                      required
                      value={firstname}
                      isValid={isValidFirstName}
                  />
                  <Input
                      id={EInput.LAST_NAME.id}
                      name={EInput.LAST_NAME.name}
                      type={EInput.LAST_NAME.type}
                      placeholder={t(EInput.LAST_NAME.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidLastName: isNameValid(lastname)
                        });
                      }}
                      required
                      value={lastname}
                      isValid={isValidLastName}
                  />
                  <Input
                      id={EInput.USERNAME.id}
                      name={EInput.USERNAME.name}
                      type={EInput.USERNAME.type}
                      placeholder={t(EInput.USERNAME.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidUsername: isUsernameValid(username)
                        });
                      }}
                      required
                      value={username}
                      isValid={isValidUsername}
                  />
                  <Input
                      id={EInput.EMAIL.id}
                      name={EInput.EMAIL.name}
                      type={EInput.EMAIL.type}
                      placeholder={t(EInput.EMAIL.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidEmail: isEmailValid(email)
                        });
                      }}
                      required
                      value={email}
                      isValid={isValidEmail}
                  />
                  <Input
                      id={EInput.PASSWORD.id}
                      name={EInput.PASSWORD.name}
                      type={EInput.PASSWORD.type}
                      placeholder={t(EInput.PASSWORD.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidPassword: isPasswordValid(password)
                        });
                      }}
                      required
                      value={password}
                      isValid={isValidPassword}
                  />
                  <Input
                      id={EInput.REPEAT_PASSWORD.id}
                      name={EInput.REPEAT_PASSWORD.name}
                      type={EInput.REPEAT_PASSWORD.type}
                      placeholder={t(EInput.REPEAT_PASSWORD.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        const {password, repeatpassword} = this.props;
                        this.setState({
                          isValidRepeatPassword: password === repeatpassword
                        });
                      }}
                      required
                      value={repeatpassword}
                      isValid={isValidRepeatPassword}
                  />
                  <Row>
                    <div className="col-7"/>
                    <div className="col-5">
                      <Button
                          type="submit"
                          title={t("register")}
                          onClick={this.handleRegister}
                      />
                    </div>
                  </Row>
                </form>
                <p className="mb-0">
                  <ALink to="/login" title={t("already-registered")}/>
                </p>
              </div>
              {isLoading && (
                  <div className="overlay">
                    <i className="fas fa-sync-alt fa-spin"/>
                  </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default translate("common")(RegisterPage);
