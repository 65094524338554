import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import employerTypes from "../types";

import {
  getEmployersFailed,
  getEmployersStart,
  getEmployersSuccess
} from "../actions";

export const callToGetEmployers = userId =>
    get({
      route: `/employers/user/${userId}`
    });

export function* getEmployers({userId}) {
  try {
    yield put(getEmployersStart());
    const apiResponse = yield call(callToGetEmployers, userId);
    yield put(getEmployersSuccess(apiResponse.data));
  } catch (error) {
    yield put(getEmployersFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getEmployersSaga() {
  yield takeLatest(employerTypes.GET_EMPLOYERS, getEmployers);
}
