/* eslint-disable jsx-a11y/no-autofocus */

import React from "react";
import PropTypes from "prop-types";

import Select from "../Select";
import {getDefaultValue} from "../../../utils/inputUtils";

export default class CurrencySelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    currencies: PropTypes.arrayOf(
        PropTypes.shape({
          currency_id: PropTypes.number,
          user_id: PropTypes.number,
          name: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
          active: PropTypes.number
        })
    ).isRequired
  };

  state = {
    options: []
  };

  componentWillMount = () => {
    const {currencies} = this.props;
    const options = [];

    currencies.forEach(element => {
      const currency = {
        value: element.currency_id,
        name: element.name
      };

      options.push(currency);
    });

    this.setState({options});
  };

  componentDidMount = () => {
    const {selectedValue, setDefaultValue} = this.props;
    const {options} = this.state;

    if (
        setDefaultValue !== undefined &&
        options.length > 0 &&
        (selectedValue === undefined || selectedValue === null)
    ) {
      setDefaultValue(options[0].value, "currency");
    }
  };

  render = () => {
    const {options} = this.state;
    const {onChange, icon, label, selectedValue} = this.props;
    const defaultValue = getDefaultValue(options, selectedValue);

    return (
        <Select
            id="currency"
            name="currency"
            icon={icon}
            label={label}
            options={options}
            onChange={onChange}
            value={defaultValue}
            required
        />
    );
  };
}
