import {call, put, takeLatest} from "redux-saga/effects";
import {deleteRequest} from "../../utils/axios-wrapper";
import employerTypes from "../types";

import {
  deleteEmployerFailed,
  deleteEmployerStart,
  deleteEmployerSuccess
} from "../actions";

export const callToDeleteEmployer = employerId =>
    deleteRequest({
      route: `/employers/${employerId}`
    });

export function* deleteEmployer({employerId}) {
  try {
    yield put(deleteEmployerStart());
    const apiResponse = yield call(callToDeleteEmployer, employerId);
    yield put(deleteEmployerSuccess(apiResponse.data));
  } catch (error) {
    yield put(deleteEmployerFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* deleteEmployerSaga() {
  yield takeLatest(employerTypes.DELETE_EMPLOYER, deleteEmployer);
}
