import {call, put, takeLatest} from "redux-saga/effects";
import {post} from "../../utils/axios-wrapper";
import currenciesTypes from "../types";

import {
  postCurrencyFailed,
  postCurrencyStart,
  postCurrencySuccess
} from "../actions";

export const callToPostCurrency = data =>
    post({
      route: `/currencies`,
      data
    });

export function* postCurrency({dataRequest}) {
  try {
    yield put(postCurrencyStart());
    const apiResponse = yield call(callToPostCurrency, dataRequest);
    yield put(postCurrencySuccess(apiResponse.data));
  } catch (error) {
    yield put(postCurrencyFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postCurrencySaga() {
  yield takeLatest(currenciesTypes.POST_CURRENCY, postCurrency);
}
