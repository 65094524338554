import React from "react";
import PropTypes from "prop-types";

export default class SidebarHeader extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string
  };

  static defaultProps = {
    title: "examples"
  };

  render() {
    const {title} = this.props;

    return <li className="nav-header">{title.toUpperCase()}</li>;
  }
}
