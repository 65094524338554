import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  getContractsFailed,
  getContractsStart,
  getContractsSuccess
} from "../actions";

export const callToGetContracts = userId =>
    get({
      route: `/contracts/user/${userId}`
    });

export function* getContracts({userId}) {
  try {
    yield put(getContractsStart());
    const apiResponse = yield call(callToGetContracts, userId);
    yield put(getContractsSuccess(apiResponse.data));
  } catch (error) {
    yield put(getContractsFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getContractsSaga() {
  yield takeLatest(contractTypes.GET_CONTRACTS, getContracts);
}
