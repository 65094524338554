import {call, put, takeLatest} from "redux-saga/effects";
import {putRequest} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  putContractFailed,
  putContractStart,
  putContractSuccess
} from "../actions";

export const callToPutContract = data =>
    putRequest({
      route: `/contract/${data.contract_id}`,
      data
    });

export function* putContract({dataRequest}) {
  try {
    yield put(putContractStart());
    const apiResponse = yield call(callToPutContract, dataRequest);
    yield put(putContractSuccess(apiResponse.data));
  } catch (error) {
    yield put(putContractFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* putContractSaga() {
  yield takeLatest(contractTypes.PUT_CONTRACT, putContract);
}
