import React from "react";
import PropTypes from "prop-types";
import NavbarLink from "../NavbarLink";
import NavbarLinks from "../NavbarLinks";
import NavbarNotifications from "../NavbarNotifications";

import {ENavigation} from "../../constants";

export default class Navbar extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {t} = this.props;
    const userLocation = window.location.pathname;

    return (
        <nav
            className="main-header navbar navbar-expand navbar-dark navbar-primary">
          <NavbarLinks>
            <NavbarLink
                title={t(ENavigation.HOME.title)}
                to={ENavigation.HOME.to}
                isActive={ENavigation.HOME.to === userLocation}
            />
            <NavbarLink
                title={t(ENavigation.CURRENCIES.title)}
                to={ENavigation.CURRENCIES.to}
                isActive={ENavigation.CURRENCIES.to === userLocation}
            />
            <NavbarLink
                title={t(ENavigation.EMPLOYERS.title)}
                to={ENavigation.EMPLOYERS.to}
                isActive={ENavigation.EMPLOYERS.to === userLocation}
            />
            <NavbarLink
                title={t(ENavigation.CONTRACTS.title)}
                to={ENavigation.CONTRACTS.to}
                isActive={ENavigation.CONTRACTS.to === userLocation}
            />
          </NavbarLinks>

          <NavbarNotifications>{/* <Logout /> */}</NavbarNotifications>
        </nav>
    );
  }
}
