export default {
  RESET_EMPLOYERS: "employers/RESET_EMPLOYERS",
  RESET_EMPLOYER: "employers/RESET_EMPLOYER",

  SET_EMPLOYER_INFO: "employers/SET_EMPLOYER_INFO",

  GET_EMPLOYERS_START: "employers/GET_EMPLOYERS_START",
  GET_EMPLOYERS: "employers/GET_EMPLOYERS",
  GET_EMPLOYERS_SUCCESS: "employers/GET_EMPLOYERS_SUCCESS",
  GET_EMPLOYERS_FAILED: "employers/GET_EMPLOYERS_FAILED",

  GET_EMPLOYER_START: "employers/GET_EMPLOYER_START",
  GET_EMPLOYER: "employers/GET_EMPLOYER",
  GET_EMPLOYER_SUCCESS: "employers/GET_EMPLOYER_SUCCESS",
  GET_EMPLOYER_FAILED: "employers/GET_EMPLOYER_FAILED",

  POST_EMPLOYER_START: "employers/POST_EMPLOYER_START",
  POST_EMPLOYER: "employers/POST_EMPLOYER",
  POST_EMPLOYER_SUCCESS: "employers/POST_EMPLOYER_SUCCESS",
  POST_EMPLOYER_FAILED: "employers/POST_EMPLOYER_FAILED",

  PUT_EMPLOYER_START: "employers/PUT_EMPLOYER_START",
  PUT_EMPLOYER: "employers/PUT_EMPLOYER",
  PUT_EMPLOYER_SUCCESS: "employers/PUT_EMPLOYER_SUCCESS",
  PUT_EMPLOYER_FAILED: "employers/PUT_EMPLOYER_FAILED",

  DELETE_EMPLOYER_START: "employers/DELETE_EMPLOYER_START",
  DELETE_EMPLOYER: "employers/DELETE_EMPLOYER",
  DELETE_EMPLOYER_SUCCESS: "employers/DELETE_EMPLOYER_SUCCESS",
  DELETE_EMPLOYER_FAILED: "employers/DELETE_EMPLOYER_FAILED"
};
