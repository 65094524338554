import React from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listWeekPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";

import i18n from "i18next";
import moment from "moment";

import hrLocale from "@fullcalendar/core/locales/hr";
import enLocale from "@fullcalendar/core/locales/en-gb";

import Card from "../Card";
import Button from "../elements/Button";
import Row from "../bootstrap/Row";

import {
  DATE_FORMAT,
  DND_EVENT,
  DND_EVENTS,
  EColors,
  EInput,
  IS_VALID_CLASS
} from "../../constants";

import "./calendar.scss";

const submittedColor = {
  textColor: EColors.WHITE,
  borderColor: EColors.SUCCESS,
  backgroundColor: EColors.SUCCESS
};

const upcomingColor = {
  textColor: EColors.WHITE,
  borderColor: EColors.PRIMARY,
  backgroundColor: EColors.PRIMARY
};

const todaysColor = {
  textColor: EColors.BLACK,
  borderColor: EColors.WARNING,
  backgroundColor: EColors.WARNING
};

const unsubmittedColor = {
  textColor: EColors.WHITE,
  borderColor: EColors.DANGER,
  backgroundColor: EColors.DANGER
};

const views = {
  customTwoDay: {
    type: "timeGrid",
    duration: {days: 2}
    // visibleRange: currentDate => {
    //   return {
    //     start: moment(currentDate)
    //       .subtract(1, "day")
    //       .format(DATE_FORMAT),
    //     end: moment(currentDate)
    //       .add(2, "day")
    //       .format(DATE_FORMAT)
    //   };
    // }
  }
};

export default class Calendar extends React.Component {
  calendarComponentRef = React.createRef();

  static propTypes = {
    title: PropTypes.string,
    t: PropTypes.func,
    onDateClick: PropTypes.func,
    onEventClick: PropTypes.func,
    onEventDrop: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool
  };

  static defaultProps = {
    title: "Calendar",
    t: () => console.debug("translations...."),
    onDateClick: () => console.debug("onDateClick...."),
    onEventClick: () => console.debug("onEventClick...."),
    onEventDrop: () => console.debug("onEventDrop...."),
    loading: true,
    data: []
  };

  state = {
    calendarWeekends: true
  };

  componentDidMount() {
    const draggableElement = document.getElementById(DND_EVENTS);

    if (draggableElement) {
      new Draggable(draggableElement, {
        itemSelector: `.${DND_EVENT}`,
        eventData: function (eventElement) {
          console.debug(eventElement);
          const title = eventElement.innerText;
          return {
            title: title
          };
        }
      });
    }
  }

  render() {
    const {t, title, data, loading} = this.props;

    const eventList = this.generateEventList(data);

    return (
        <Card title={title} noPadding loading={loading}>
          <Row>
            <div className="col-12 col-sm-6 col-md-6">
              <div className="btn-group d-flex" role="group" aria-label="...">
                <Button
                    block={false}
                    color="default"
                    title={t("month")}
                    onClick={this.changeViewToMonth}
                />
                <Button
                    block={false}
                    color="default"
                    title={t("week")}
                    onClick={this.changeViewToWeek}
                />
                <Button
                    block={false}
                    color="default"
                    title={t("day")}
                    onClick={this.changeViewToDay}
                />
                <Button
                    block={false}
                    color="default"
                    title={t("list")}
                    onClick={this.changeViewToList}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6">
              <div className="btn-group d-flex" role="group" aria-label="...">
                <Button
                    className="flex-fill "
                    block={false}
                    color="default"
                    title={`← ${t("previous")}`}
                    onClick={this.changeToPrevious}
                />
                <Button
                    className="flex-fill "
                    block={false}
                    color="default"
                    title={`${t("next")} →`}
                    onClick={this.changeToNext}
                />
                <Button
                    className="flex-fill "
                    block={false}
                    color="default"
                    title={t("today")}
                    onClick={this.changeToToday}
                />
              </div>
            </div>
          </Row>
          <FullCalendar
              locales={[hrLocale, enLocale]}
              locale={i18n.language}
              height="auto"
              defaultView="dayGridWeek"
              header={{
                left: "",
                center: "title",
                right: ""
                // left: "prev,next today",
                // center: "title",
                // right: "dayGridMonth,timeGridWeek,timeGridDay"
              }}
              plugins={[
                interactionPlugin,
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listWeekPlugin
              ]}
              views={views}
              ref={this.calendarComponentRef}
              weekends={this.state.calendarWeekends}
              events={eventList}
              eventClick={this.handleEventClick}
              dateClick={this.handleDateClick}
              droppable={true}
              editable={false}
              eventReceive={info => {
                if (
                    info === null ||
                    info.event === null ||
                    info.event.start === null ||
                    info.draggedEl === null ||
                    info.draggedEl.dataset === null ||
                    info.draggedEl.dataset.event === null
                ) {
                  return;
                }

                // Remove it from the calendar (because we are going to call api)
                info.event.remove();

                const date = moment(info.event.start).format(DATE_FORMAT);
                const draggedElementData = JSON.parse(
                    info.draggedEl.dataset.event);

                if (date !== null && draggedElementData.employer_id !== null) {
                  this.handleEventDrop(draggedElementData.employer_id, date);
                } else {
                  // TODO warn?
                }
              }}
          />
          <div className="d-flex flex-row justify-content-end">
          <span className="mr-2">
            <i className="fas fa-square text-danger"/>
            {t("unsubmitted-contracts")}
          </span>
            <span className="mr-2">
            <i className="fas fa-square text-warning"/> {t("todays-contracts")}
          </span>
            <span className="mr-2">
            <i className="fas fa-square text-primary"/>
              {t("upcoming-contracts")}
          </span>
            <span className="mr-2">
            <i className="fas fa-square text-success"/>
              {t("submitted-contracts")}
          </span>
          </div>
        </Card>
    );
  }

  generateEventList = data => {
    const eventList = [];

    data.forEach(element => {
      const {
        contract_id,
        first_name,
        last_name,
        date,
        start_time,
        finish_time,
        submitted
      } = element;

      let event;

      event = {
        contractId: contract_id,
        title: `${last_name} ${first_name}`,
        start: `${date} ${start_time}`
      };

      if (finish_time && finish_time !== null) {
        event = {
          ...event,
          end: `${date} ${finish_time}`
        };
      }

      if (this.isEventInPastAndUnsubmitted(date, submitted)) {
        event = {
          ...event,
          ...unsubmittedColor
        };
      } else if (this.isEventTodayAndUnsubmitted(date, submitted)) {
        event = {
          ...event,
          ...todaysColor
        };
      } else if (this.isEventSubmitted(submitted)) {
        event = {
          ...event,
          ...submittedColor
        };
      } else {
        event = {
          ...event,
          ...upcomingColor
        };
      }

      eventList.push(event);
    });

    return eventList;
  };

  isEventInPastAndUnsubmitted = (date, submitted) => {
    return (
        moment(date).format(DATE_FORMAT) < moment().format(DATE_FORMAT) &&
        submitted === 0
    );
  };

  isEventTodayAndUnsubmitted = (date, submitted) => {
    return date === moment().format(DATE_FORMAT) && submitted === 0;
  };

  isEventSubmitted = submitted => {
    return submitted === 1;
  };

  changeViewToList = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.changeView("listWeek");
  };

  changeViewToDay = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.changeView("customTwoDay");
  };

  changeViewToWeek = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.changeView("dayGridWeek");
  };

  changeViewToMonth = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.changeView("dayGridMonth");
  };

  changeToPrevious = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.prev();
  };

  changeToNext = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.next();
  };

  changeToToday = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.today();
  };

  changeHeight = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.setOption("height", 2000);
  };

  toggleWeekends = () => {
    this.setState({
      calendarWeekends: !this.state.calendarWeekends
    });
  };

  handleDateClick = arg => {
    const {onDateClick} = this.props;
    onDateClick(arg.dateStr);
  };

  handleEventClick = arg => {
    const {onEventClick} = this.props;
    onEventClick(arg.event.extendedProps);
  };

  handleEventDrop = (employerId, date) => {
    const {onEventDrop} = this.props;

    const priceElement = document.getElementById(EInput.CONTRACT_PRICE.id);

    const startTimeElement = document.getElementById(
        EInput.CONTRACT_START_TIME.id
    );

    const currencyElement = document.getElementById(EInput.CURRENCY.id);

    const currencyId =
        currencyElement.options[currencyElement.selectedIndex].value;

    if (
        date !== null &&
        employerId !== null &&
        priceElement.classList.contains(IS_VALID_CLASS) &&
        startTimeElement.classList.contains(IS_VALID_CLASS)
    ) {
      onEventDrop(
          employerId,
          currencyId,
          date,
          startTimeElement.value,
          priceElement.value
      );
    }
  };
}
