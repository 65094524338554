export default {
  RESET_UNSUBMITTED_CONTRACTS: "contracts/RESET_UNSUBMITTED_CONTRACTS",

  GET_UNSUBMITTED_CONTRACTS_START: "contracts/GET_UNSUBMITTED_CONTRACTS_START",
  GET_UNSUBMITTED_CONTRACTS: "contracts/GET_UNSUBMITTED_CONTRACTS",

  GET_UNSUBMITTED_CONTRACTS_SUCCESS:
      "contracts/GET_UNSUBMITTED_CONTRACTS_SUCCESS",

  GET_UNSUBMITTED_CONTRACTS_FAILED: "contracts/GET_UNSUBMITTED_CONTRACTS_FAILED"
};
