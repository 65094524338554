import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";
// Component
import DashboardPage from "./component";

import {
  deleteContract,
  getContract,
  getContracts,
  postContract,
  putContract,
  resetContract,
  setContractInfo
} from "../../modules/contracts/actions";

import {getUnsubmittedContracts} from "../../modules/unsubmitted-contracts/actions";

const mapStateToProps = ({
  user,
  contracts,
  currencies,
  employers,
  unsubmittedContracts
}) => ({
  hasFetchedDataEmployers: employers.hasFetchedData,
  isLoadingEmployers: employers.isLoading,

  hasFetchedDataCurrencies: currencies.hasFetchedData,
  isLoadingCurrencies: currencies.isLoading,

  hasFetchedDataContracts: contracts.hasFetchedData,
  isLoadingContracts: contracts.isLoading,

  currencies: currencies.currencies,
  employers: employers.employers,
  contracts: contracts.contracts,
  contract: contracts.contract,

  userId: user.authUser.userId,

  unsubmittedContracts: unsubmittedContracts.apiResponse,
  hasFetchedDataUnsubmittedContracts: unsubmittedContracts.hasFetchedData,
  isLoadingUnsubmittedContract: unsubmittedContracts.isLoading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push,
          setContractInfo,
          resetContract,
          deleteContract,
          postContract,
          putContract,
          getContracts,
          getContract,
          getUnsubmittedContracts
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPage);
