import React from "react";
import PropTypes from "prop-types";

import {DND_EVENT} from "../../../constants";

export default class DragAndDropElement extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    dataEvent: PropTypes.object.isRequired
  };

  static defaultProps = {
    color: "bg-primary"
  };

  render = () => {
    const {color, title, dataEvent} = this.props;

    return (
        <div
            className={`${DND_EVENT} ${color}`}
            data-event={JSON.stringify(dataEvent)}
        >
          {title}
        </div>
    );
  };
}
