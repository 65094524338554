/* eslint-disable jsx-a11y/no-autofocus */

import React from "react";
import PropTypes from "prop-types";

import Select from "../Select";
import {getDefaultValue} from "../../../utils/inputUtils";

export default class EmployerSelect extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    employers: PropTypes.arrayOf(
        PropTypes.shape({
          employer_id: PropTypes.number,
          user_id: PropTypes.number,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          nickname: PropTypes.string,
          note: PropTypes.string,
          active: PropTypes.number
        })
    ).isRequired
  };

  state = {
    options: []
  };

  componentWillMount = () => {
    const {employers} = this.props;
    const options = [];

    employers.forEach(element => {
      const employer = {
        value: element.employer_id,
        name: `${element.last_name} ${element.first_name}`
      };

      options.push(employer);
    });

    this.setState({options});
  };

  componentDidMount = () => {
    const {selectedValue, setDefaultValue} = this.props;
    const {options} = this.state;

    if (
        setDefaultValue !== undefined &&
        options.length > 0 &&
        (selectedValue === undefined || selectedValue === null)
    ) {
      setDefaultValue(options[0].value, "employer");
    }
  };

  render = () => {
    const {options} = this.state;
    const {onChange, icon, label, selectedValue} = this.props;
    const defaultValue = getDefaultValue(options, selectedValue);

    return (
        <Select
            id="employer"
            name="employer"
            icon={icon}
            label={label}
            options={options}
            onChange={onChange}
            value={defaultValue}
            required
        />
    );
  };
}
