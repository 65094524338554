import {call, put, takeLatest} from "redux-saga/effects";
import {putRequest} from "../../utils/axios-wrapper";
import currenciesTypes from "../types";

import {
  putCurrencyFailed,
  putCurrencyStart,
  putCurrencySuccess
} from "../actions";

export const callToPutCurrency = data =>
    putRequest({
      route: `/currencies/${data.currency_id}`,
      data
    });

export function* putCurrency({dataRequest}) {
  try {
    yield put(putCurrencyStart());
    const apiResponse = yield call(callToPutCurrency, dataRequest);
    yield put(putCurrencySuccess(apiResponse.data));
  } catch (error) {
    yield put(putCurrencyFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* putCurrencySaga() {
  yield takeLatest(currenciesTypes.PUT_CURRENCY, putCurrency);
}
