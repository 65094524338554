import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";
// Component
import CurrencyPage from "./component";

import {
  deleteCurrency,
  getCurrencies,
  getCurrency,
  postCurrency,
  putCurrency,
  resetCurrency,
  setCurrencyInfo
} from "../../modules/currencies/actions";

const mapStateToProps = ({user, currencies}) => ({
  hasFetchedData: currencies.hasFetchedData,
  isLoading: currencies.isLoading,
  apiResponse: currencies.apiResponse,

  userId: user.authUser.userId,
  currencies: currencies.currencies,

  code: currencies.currency.code,
  name: currencies.currency.name,
  currency_id: currencies.currency.currency_id
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push,
          setCurrencyInfo,
          resetCurrency,
          getCurrency,
          getCurrencies,
          postCurrency,
          putCurrency,
          deleteCurrency
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrencyPage);
