export default {
  RESET_REPORTS: "reports/RESET_REPORTS",

  SET_EMPLOYER_INFO: "reports/SET_REPORT_INFO",
  APPLY_REPORT_FILTER: "reports/APPLY_REPORT_FILTER",

  GET_INCOME_FOR_MONTH_START: "reports/GET_INCOME_FOR_MONTH_START",
  GET_INCOME_FOR_MONTH: "reports/GET_INCOME_FOR_MONTH",
  GET_INCOME_FOR_MONTH_SUCCESS: "reports/GET_INCOME_FOR_MONTH_SUCCESS",
  GET_INCOME_FOR_MONTH_FAILED: "reports/GET_INCOME_FOR_MONTH_FAILED",

  GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_START:
      "reports/GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_START",
  GET_NUMBER_OF_UNSUBMITTED_CONTRACTS:
      "reports/GET_NUMBER_OF_UNSUBMITTED_CONTRACTS",
  GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_SUCCESS:
      "reports/GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_SUCCESS",
  GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_FAILED:
      "reports/GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_FAILED"
};
