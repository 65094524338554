import reportTypes from "./types";

const initialState = {
  reportUnsubmittedContracts: {
    isLoading: false,
    hasFetchedData: false,
    hasError: false,
    value: 0
  }
};

export const resetReports = state => ({
  ...state,
  reportUnsubmittedContracts: {
    isLoading: false,
    hasFetchedData: false,
    hasError: false,
    value: 0
  }
});

export const getIncomeForMonthStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getIncomeForMonthSuccess = (state, {reportIncome}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  reportIncome
});

export const getIncomeForMonthFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getNumberOfUnsubmittedContractsStart = state => ({
  ...state,
  reportUnsubmittedContracts: {
    ...state.reportUnsubmittedContracts,
    isLoading: true,
    hasFetchedData: false,
    hasError: false
  }
});

export const getNumberOfUnsubmittedContractsSuccess = (
    state,
    {numberOfUnsubmittedContracts}
) => ({
  ...state,
  reportUnsubmittedContracts: {
    ...state.reportUnsubmittedContracts,
    isLoading: false,
    hasFetchedData: true,
    hasError: false,
    value: numberOfUnsubmittedContracts.value
  }
});

export const getNumberOfUnsubmittedContractsFailed = state => ({
  ...state,
  reportUnsubmittedContracts: {
    ...state.reportUnsubmittedContracts,
    isLoading: false,
    hasFetchedData: true,
    hasError: true
  }
});

const reducer = {
  [reportTypes.RESET_REPORTS]: resetReports,
  [reportTypes.GET_INCOME_FOR_MONTH_START]: getIncomeForMonthStart,
  [reportTypes.GET_INCOME_FOR_MONTH_SUCCESS]: getIncomeForMonthSuccess,
  [reportTypes.GET_INCOME_FOR_MONTH_FAILED]: getIncomeForMonthFailed,

  [reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_START]: getNumberOfUnsubmittedContractsStart,
  [reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_SUCCESS]: getNumberOfUnsubmittedContractsSuccess,
  [reportTypes.GET_NUMBER_OF_UNSUBMITTED_CONTRACTS_FAILED]: getNumberOfUnsubmittedContractsFailed
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
