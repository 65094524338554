import React from "react";
import PropTypes from "prop-types";

// TODO This needs to be fixed with data-widget
// https://adminlte.io/docs/2.4/js-tree

// Made hotfix with code, no smooth transation

export default class SidebarDropdown extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    icon: "fas fa-tachometer-alt",
    title: "Dashboard"
  };

  state = {
    isMenuOpen: false
  };

  toggleIsMenuOpen = () => {
    const {isMenuOpen} = this.state;
    this.setState({isMenuOpen: !isMenuOpen});
  };

  render() {
    const {isMenuOpen} = this.state;
    const {icon, title} = this.props;

    return (
        <li
            className={`
        nav-item has-treeview ${isMenuOpen ? " menu-open" : ""}`}
        >
          <span
              tabIndex="0"
              onKeyDown={this.toggleIsMenuOpen}
              onClick={this.toggleIsMenuOpen}
              style={{cursor: "pointer"}}
              className={`nav-link ${isMenuOpen ? "bg-default" : ""}`}
          >
            <i className={`nav-icon ${icon}`}/>
            <p>
              {title}
              <i className="right fas fa-angle-left"/>
            </p>
          </span>
          <ul className="nav nav-treeview">{this.props.children}</ul>
        </li>
    );
  }
}
