import userTypes from "./types";

const initialState = {
  authUser: {
    accessToken: null,
    userId: null
  },
  login: {
    username: "",
    password: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  },
  register: {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    repeatpassword: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  }
};

export const resetUser = state => ({
  ...state,
  authUser: {
    accessToken: null,
    userId: null
  },
  login: {
    username: "",
    password: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  },
  register: {
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    repeatpassword: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  }
});

export const resetLogin = state => ({
  ...state,
  login: {
    ...state.login,
    username: "",
    password: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  }
});

export const resetRegister = state => ({
  ...state,
  login: {
    ...state.login,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    repeatpassword: "",
    isLoading: false,
    hasFetchedData: false,
    hasError: false
  }
});

export const setLoginInfo = (state, {newLoginInfo}) => ({
  ...state,
  login: {
    ...state.login,
    ...newLoginInfo
  }
});

export const setRegisterInfo = (state, {newRegisterInfo}) => ({
  ...state,
  register: {
    ...state.register,
    ...newRegisterInfo
  }
});

// -------

export const postLoginStart = state => ({
  ...state,
  login: {
    ...state.login,
    isLoading: true,
    hasFetchedData: false,
    hasError: false
  }
});

export const postLoginFail = state => ({
  ...state,
  login: {
    ...state.login,
    isLoading: false,
    hasFetchedData: true,
    hasError: true
  }
});

export const postLoginSuccess = (state, {userdata}) => {
  if (userdata.failed) {
    return {
      ...state,
      authUser: {
        accessToken: null,
        userId: null
      },
      login: {
        ...state.login,
        isLoading: false,
        hasFetchedData: true,
        hasError: true
      }
    };
  }

  return {
    ...state,
    authUser: {
      ...state.authUser,
      userId: userdata.user_id
    },
    login: {
      ...state.login,
      isLoading: false,
      hasFetchedData: true,
      hasError: false
    }
  };
};

// -------

export const postRegisterStart = state => ({
  ...state,
  register: {
    ...state.register,
    isLoading: true,
    hasFetchedData: false,
    hasError: false
  }
});

export const postRegisterFail = state => ({
  ...state,
  register: {
    ...state.register,
    isLoading: false,
    hasFetchedData: true,
    hasError: true
  }
});

export const postRegisterSuccess = (state, {userdata}) => {
  if (userdata.failed) {
    return {
      ...state,
      authUser: {
        accessToken: null,
        userId: null
      },
      register: {
        ...state.register,
        isLoading: false,
        hasFetchedData: true,
        hasError: true
      }
    };
  }

  return {
    ...state,
    authUser: {
      ...state.authUser,
      userId: userdata.user_id
    },
    register: {
      ...state.register,
      isLoading: false,
      hasFetchedData: true,
      hasError: false
    }
  };
};

const reducer = {
  [userTypes.RESET_USER]: resetUser,
  [userTypes.RESET_LOGIN]: resetLogin,
  [userTypes.RESET_REGISTER]: resetRegister,
  [userTypes.SET_LOGIN_INFO]: setLoginInfo,
  [userTypes.SET_REGISTER_INFO]: setRegisterInfo,
  [userTypes.POST_LOGIN_START]: postLoginStart,
  [userTypes.POST_LOGIN_FAIL]: postLoginFail,
  [userTypes.POST_LOGIN_SUCCESS]: postLoginSuccess,
  [userTypes.POST_REGISTER_START]: postRegisterStart,
  [userTypes.POST_REGISTER_FAIL]: postRegisterFail,
  [userTypes.POST_REGISTER_SUCCESS]: postRegisterSuccess
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
