import {call, put, takeLatest} from "redux-saga/effects";
import {post} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  postContractFailed,
  postContractStart,
  postContractSuccess
} from "../actions";

export const callToPostContract = dataRequest =>
    post({
      route: "/contract",
      data: dataRequest
    });

export function* postContract({dataRequest}) {
  try {
    yield put(postContractStart());
    const apiResponse = yield call(callToPostContract, dataRequest);
    yield put(postContractSuccess(apiResponse.data));
  } catch (error) {
    yield put(postContractFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postContractSaga() {
  yield takeLatest(contractTypes.POST_CONTRACT, postContract);
}
