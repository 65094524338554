import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import currenciesTypes from "../types";

import {
  getCurrencyFailed,
  getCurrencyStart,
  getCurrencySuccess
} from "../actions";

export const callToGetCurrency = currencyId =>
    get({
      route: `/currencies/${currencyId}`
    });

export function* getCurrency({currencyId}) {
  try {
    yield put(getCurrencyStart());
    const apiResponse = yield call(callToGetCurrency, currencyId);
    yield put(getCurrencySuccess(apiResponse.data));
  } catch (error) {
    yield put(getCurrencyFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getCurrencySaga() {
  yield takeLatest(currenciesTypes.GET_CURRENCY, getCurrency);
}
