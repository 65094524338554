import React from "react";
import PropTypes from "prop-types";

export default class SidebarLink extends React.Component {
  static propTypes = {
    to: PropTypes.string,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    badgeType: PropTypes.oneOf(["info", "success", "warning", "danger"]),
    badgeNumber: PropTypes.number,
    isActive: PropTypes.bool,
    isLanguageIcon: PropTypes.bool,
    onClickAction: PropTypes.func
  };

  static defaultProps = {
    to: undefined,
    badgeType: undefined,
    badgeNumber: undefined,
    isActive: undefined,
    isLanguageIcon: undefined,
    onClickAction: undefined
  };

  render() {
    const {
      to,
      icon,
      title,
      badgeType,
      badgeNumber,
      isActive,
      isLanguageIcon,
      onClickAction,
      push
    } = this.props;

    return (
        <li className="nav-item" style={{cursor: "pointer"}}>
          <span
              onClick={onClickAction !== undefined ? onClickAction : () => push(
                  to)}
              className={`nav-link ${isActive ? "active" : ""}`}
          >
            {!isLanguageIcon && icon && <i className={`nav-icon ${icon}`}/>}
            {isLanguageIcon && (
                <span>
              <span className={icon}/>
                  &nbsp;
            </span>
            )}
            <p>
              {title}
              {badgeType && badgeNumber && (
                  <span className={`badge badge-${badgeType} right`}>
                {badgeNumber}
              </span>
              )}
            </p>
          </span>
        </li>
    );
  }
}
