import unsubmittedContractTypes from "./types";

export const getUnsubmittedContractsStart = () => ({
  type: unsubmittedContractTypes.GET_UNSUBMITTED_CONTRACTS_START
});

export const getUnsubmittedContracts = userId => ({
  type: unsubmittedContractTypes.GET_UNSUBMITTED_CONTRACTS,
  userId
});

export const getUnsubmittedContractsSuccess = apiResponse => ({
  type: unsubmittedContractTypes.GET_UNSUBMITTED_CONTRACTS_SUCCESS,
  apiResponse
});

export const getUnsubmittedContractsFailed = () => ({
  type: unsubmittedContractTypes.GET_UNSUBMITTED_CONTRACTS_FAILED
});

export const resetUnsubmittedContracts = () => ({
  type: unsubmittedContractTypes.RESET_UNSUBMITTED_CONTRACTS
});
