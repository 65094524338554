import employerTypes from "./types";

const initialState = {
  isLoading: false,
  hasFetchedData: false,
  apiResponse: null,
  employers: [],
  employer: {}
};

export const resetEmployers = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: null,
  employers: [],
  employer: {}
});

export const resetEmployer = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: {},
  employer: {}
});

export const setEmployerInfo = (state, {newEmployerInfo}) => ({
  ...state,
  employer: {
    ...state.employer,
    ...newEmployerInfo
  }
});

export const putSubmitEmployerStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const putSubmitEmployerSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const putSubmitEmployerFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getEmployersStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getEmployersSuccess = (state, {employers}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  employers,
  filteredEmployers: employers
});

export const getEmployersFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getEmployerStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getEmployerSuccess = (state, {employer}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  employer
});

export const getEmployerFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const postEmployerStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const postEmployerSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const postEmployerFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const putEmployerStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const putEmployerSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const putEmployerFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const deleteEmployerStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const deleteEmployerSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const deleteEmployerFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

const reducer = {
  [employerTypes.RESET_EMPLOYERS]: resetEmployers,
  [employerTypes.RESET_EMPLOYER]: resetEmployer,
  [employerTypes.SET_EMPLOYER_INFO]: setEmployerInfo,
  [employerTypes.PUT_SUBMIT_EMPLOYER_START]: putSubmitEmployerStart,
  [employerTypes.PUT_SUBMIT_EMPLOYER_SUCCESS]: putSubmitEmployerSuccess,
  [employerTypes.PUT_SUBMIT_EMPLOYER_FAILED]: putSubmitEmployerFailed,
  [employerTypes.GET_EMPLOYERS_START]: getEmployersStart,
  [employerTypes.GET_EMPLOYERS_SUCCESS]: getEmployersSuccess,
  [employerTypes.GET_EMPLOYERS_FAILED]: getEmployersFailed,
  [employerTypes.GET_EMPLOYER_START]: getEmployerStart,
  [employerTypes.GET_EMPLOYER_SUCCESS]: getEmployerSuccess,
  [employerTypes.GET_EMPLOYER_FAILED]: getEmployerFailed,
  [employerTypes.POST_EMPLOYER_START]: postEmployerStart,
  [employerTypes.POST_EMPLOYER_SUCCESS]: postEmployerSuccess,
  [employerTypes.POST_EMPLOYER_FAILED]: postEmployerFailed,
  [employerTypes.PUT_EMPLOYER_START]: putEmployerStart,
  [employerTypes.PUT_EMPLOYER_SUCCESS]: putEmployerSuccess,
  [employerTypes.PUT_EMPLOYER_FAILED]: putEmployerFailed,
  [employerTypes.DELETE_EMPLOYER_START]: deleteEmployerStart,
  [employerTypes.DELETE_EMPLOYER_SUCCESS]: deleteEmployerSuccess,
  [employerTypes.DELETE_EMPLOYER_FAILED]: deleteEmployerFailed
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
