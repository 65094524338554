import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import employerTypes from "../types";

import {
  getEmployerFailed,
  getEmployerStart,
  getEmployerSuccess
} from "../actions";

export const callToGetEmployer = employerId =>
    get({
      route: `/employers/${employerId}`
    });

export function* getEmployer({employerId}) {
  try {
    yield put(getEmployerStart());
    const apiResponse = yield call(callToGetEmployer, employerId);
    yield put(getEmployerSuccess(apiResponse.data));
  } catch (error) {
    yield put(getEmployerFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getEmployerSaga() {
  yield takeLatest(employerTypes.GET_EMPLOYER, getEmployer);
}
