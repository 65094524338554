import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";
// Component
import EmployerPage from "./component";

import {
  deleteEmployer,
  getEmployer,
  getEmployers,
  postEmployer,
  putEmployer,
  resetEmployer,
  setEmployerInfo
} from "../../modules/employers/actions";

const mapStateToProps = ({user, employers}) => ({
  hasFetchedData: employers.hasFetchedData,
  isLoading: employers.isLoading,
  apiResponse: employers.apiResponse,

  userId: user.authUser.userId,
  employers: employers.employers,
  firstName: employers.employer.first_name,
  lastName: employers.employer.last_name,
  nickname: employers.employer.nickname,
  note: employers.employer.note,
  employer_id: employers.employer.employer_id
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push,
          setEmployerInfo,
          resetEmployer,
          getEmployers,
          getEmployer,
          postEmployer,
          putEmployer,
          deleteEmployer
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployerPage);
