export default {
  RESET_USER: "user/RESET_USER",
  RESET_LOGIN: "user/RESET_LOGIN",
  RESET_REGISTER: "user/RESET_REGISTER",

  SET_LOGIN_INFO: "user/SET_LOGIN_INFO",
  SET_REGISTER_INFO: "user/SET_REGISTER_INFO",

  POST_LOGIN: "user/POST_LOGIN",
  POST_LOGIN_START: "user/POST_LOGIN_START",
  POST_LOGIN_FAIL: "user/POST_LOGIN_FAIL",
  POST_LOGIN_SUCCESS: "user/POST_LOGIN_SUCCESS",

  POST_REGISTER: "user/POST_REGISTER",
  POST_REGISTER_START: "user/POST_REGISTER_START",
  POST_REGISTER_FAIL: "user/POST_REGISTER_FAIL",
  POST_REGISTER_SUCCESS: "user/POST_REGISTER_SUCCESS"
};
