import React from "react";
import PropTypes from "prop-types";

import packageJson from "../../../package.json";

export default class PageFooter extends React.PureComponent {
  static propTypes = {
    domain: PropTypes.string,
    version: PropTypes.string
  };

  static defaultProps = {
    domain: "zanoski.com"
  };

  render() {
    const {domain} = this.props;
    const version = packageJson.version;
    const dateStart = packageJson.dateStart;

    return (
        <footer className="main-footer">
          <strong>
            Copyright © {dateStart}-{new Date().getFullYear()}
            &nbsp;
            <a href={`http://${domain}`}>{domain}</a>
            &nbsp;
          </strong>
          <div className="d-block d-sm-none">
            <div className="mb-1"/>
          </div>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> {version}
          </div>
        </footer>
    );
  }
}
