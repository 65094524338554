import React from "react";
import PropTypes from "prop-types";

export default class Container extends React.PureComponent {
  static propTypes = {
    fluid: PropTypes.bool
  };

  static defaultProps = {
    fluid: false
  };

  render() {
    const {fluid} = this.props;

    const classType = fluid ? "container-fluid" : "container";

    return <div className={classType}>{this.props.children}</div>;
  }
}
