// Libraries
import React, {Component} from "react";
import PropTypes from "prop-types";
import {Provider} from "react-redux";
import {ConnectedRouter} from "react-router-redux";
import {Route, Switch} from "react-router";

import LoginPage from "../LoginPage";
import RegisterPage from "../RegisterPage";
import DashboardPage from "../DashboardPage";
import CurrencyPage from "../CurrencyPage";
import EmployerPage from "../EmployerPage";
import ContractPage from "../ContractPage";

import {alert} from "../../utils/sweetAlert";

class App extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    userId: PropTypes.number
  };

  static defaultProps = {
    userId: -1
  };

  componentDidUpdate = () => {
    const {
      userId,
      push,
      getEmployers,
      getCurrencies,
      getContracts
    } = this.props;

    if (userId === -1) {
      push("/login");
    } else if (userId && userId !== -1) {
      getCurrencies(userId);
      getEmployers(userId);
      getContracts(userId);

      alert("success", "Welcome to Workbook");
      push("/home");
    }
  };

  componentDidMount = () => {
    const {userId, push} = this.props;

    if (!userId) {
      push("/login");
    }
  };

  handleLogin = () => {
    const {userId, push} = this.props;

    if (!userId) {
      push("/login");
    }
  };

  render = () => {
    const {store, history} = this.props;

    return (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact path="/login" component={LoginPage}/>
              <Route exact path="/register" component={RegisterPage}/>
              <Route exact path="/home" component={DashboardPage}/>
              <Route exact path="/currencies" component={CurrencyPage}/>
              <Route exact path="/employers" component={EmployerPage}/>
              <Route exact path="/contracts" component={ContractPage}/>
            </Switch>
          </ConnectedRouter>
        </Provider>
    );
  };
}

export default App;
