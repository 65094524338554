import {call, put, takeLatest} from "redux-saga/effects";
import {post} from "../../utils/axios-wrapper";
import employerTypes from "../types";

import {
  postEmployerFailed,
  postEmployerStart,
  postEmployerSuccess
} from "../actions";

export const callToPostEmployer = dataRequest =>
    post({
      route: "/employers",
      data: dataRequest
    });

export function* postEmployer({dataRequest}) {
  try {
    yield put(postEmployerStart());
    const apiResponse = yield call(callToPostEmployer, dataRequest);
    yield put(postEmployerSuccess(apiResponse.data));
  } catch (error) {
    yield put(postEmployerFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postEmployerSaga() {
  yield takeLatest(employerTypes.POST_EMPLOYER, postEmployer);
}
