import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";

import {postLogin, resetLogin, setLoginInfo} from "../../modules/user/actions";

import LoginPage from "./component";

const mapStateToProps = ({user}) => ({
  username: user.login.username,
  password: user.login.password,
  isLoading: user.login.isLoading,
  hasFetchedData: user.login.hasFetched,
  hasError: user.login.hasError
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          setLoginInfo,
          resetLogin,
          postLogin,
          push
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
