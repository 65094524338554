import moment from "moment";
import {DATE_FORMAT} from "../constants";

export function extractUnsubmittedContracts(contracts) {
  const now = moment().format(DATE_FORMAT);

  const unsubmittedContracts = contracts.filter(contract => {
    return contract.date <= now && contract.submitted !== 1;
  });

  return unsubmittedContracts;
}

export function extractSubmittedContracts(numberOfMonths, contracts) {
  const now = moment().format(DATE_FORMAT);
  const past = moment()
  .subtract(numberOfMonths, "months")
  .startOf("month")
  .format(DATE_FORMAT);

  const submittedContracts = contracts.filter(contract => {
    return (
        contract.date >= past && contract.date <= now && contract.submitted
        === 1
    );
  });

  return submittedContracts;
}

export function calculateTodoContractsForToday(contracts) {
  const now = moment().format(DATE_FORMAT);

  const todoContractsForToday = contracts.filter(contract => {
    return contract.date === now && contract.submitted !== 1;
  });

  return todoContractsForToday;
}
