import React from "react";
import {Line} from "react-chartjs-2";
import PropTypes from "prop-types";
// Intended usage: https://chartjs-plugin-datalabels.netlify.com/guide/getting-started.html
// eslint-disable-next-line no-unused-vars

import {generateRandomColor} from "../../../utils/colorUtils";

const options = {
  redraw: true,
  maintainAspectRatio: false,
  responsive: true,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {beginAtZero: true},
        gridLines: {
          display: false
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: "auto",
      align: "bottom",
      offset: 4,
      formatter: Math.round,
      font: {
        weight: "bold"
      }
    }
  }
};

export default class LineChart extends React.Component {
  static propTypes = {
    labels: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          data: PropTypes.arrayOf(PropTypes.number).isRequired
        })
    )
  };

  static defaultProps = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    data: [{label: "Sales", data: [65, 59, 80, 81, 56, 55, 40]}]
  };

  generateDataset = data => {
    const datasets = [];

    data.forEach(element => {
      const color = generateRandomColor();

      datasets.push({
        label: element.label,
        data: element.data,
        fill: false,
        backgroundColor: "transparent",
        borderColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointRadius: 6
      });
    });

    return datasets;
  };

  render() {
    const {labels, data} = this.props;
    const datasets = this.generateDataset(data);
    const chartData = {labels: labels, datasets: datasets};

    return (
        <div className="chart">
          <Line options={options} data={chartData}/>
        </div>
    );
  }
}
