import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import reportTypes from "../types";

import {
  getIncomeForMonthFailed,
  getIncomeForMonthStart,
  getIncomeForMonthSuccess
} from "../actions";

export const callToGetIncomeForMonth = (userId, year, month) =>
    get({
      route: `/income/${userId}/${year}/${month}`
    });

export function* getIncomeForMonth({userId, year, month}) {
  try {
    yield put(getIncomeForMonthStart());

    const apiResponse = yield call(
        callToGetIncomeForMonth,
        userId,
        year,
        month
    );

    yield put(getIncomeForMonthSuccess(apiResponse.data));
  } catch (error) {
    yield put(getIncomeForMonthFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getIncomeForMonthSaga() {
  yield takeLatest(reportTypes.GET_INCOME_FOR_MONTH, getIncomeForMonth);
}
