import React from "react";
import PropTypes from "prop-types";

export default class Button extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    color: PropTypes.oneOf([
      "default",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "danger"
    ]),
    title: PropTypes.string,
    block: PropTypes.bool,
    appIcon: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    flat: PropTypes.bool,
    disabled: PropTypes.bool,
    gradient: PropTypes.bool,
    outline: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    type: "button",
    color: "primary",
    title: "Button Name",
    block: true,
    appIcon: undefined,
    large: undefined,
    small: undefined,
    flat: undefined,
    disabled: undefined,
    gradient: undefined,
    outlined: undefined,
    onClick: () => {
      console.debug("Clicked");
    }
  };

  render() {
    const {
      type,
      color,
      title,
      block,
      appIcon,
      large,
      small,
      flat,
      disabled,
      gradient,
      outline,
      onClick
    } = this.props;

    let classForButton = "btn";

    if (disabled) {
      classForButton += " disabled";
    }

    if (appIcon) {
      classForButton += ` btn-app`;
      return (
          <button onClick={onClick} type={type} className={classForButton}>
            {appIcon && <i className="fas fa-save"/>}
            {title}
          </button>
      );
    }

    if (block) {
      classForButton += ` btn-block`;
    }

    if (outline) {
      classForButton += ` btn-outline-${color}`;
    } else if (gradient) {
      classForButton += ` bg-gradient-${color}`;
    } else {
      classForButton += ` btn-${color}`;
    }

    if (small) {
      classForButton += " btn-sm";
    } else if (large) {
      classForButton += " btn-lg";
    } else if (flat) {
      classForButton += " btn-flat";
    }

    return (
        <button onClick={onClick} type="button" className={classForButton}>
          {appIcon && <i className="fas fa-save"/>}
          {title}
        </button>
    );
  }
}
