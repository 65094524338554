import {connect} from "react-redux";
import {push} from "react-router-redux";
import {bindActionCreators} from "redux";

import NavbarLinkComponent from "./NavbarLink";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavbarLinkComponent);
