import {call, put, takeLatest} from "redux-saga/effects";
import {post} from "../../utils/axios-wrapper";
import userTypes from "../types";
import {postLoginFail, postLoginStart, postLoginSuccess} from "../actions";

export const callToPostLogin = payload =>
    post({
      route: `/login`,
      data: payload
    });

export function* postLogin(payload) {
  try {
    yield put(postLoginStart());
    const user = yield call(callToPostLogin, payload.login);
    yield put(postLoginSuccess(user.data));
  } catch (error) {
    yield put(postLoginFail(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postLoginSaga() {
  yield takeLatest(userTypes.POST_LOGIN, postLogin);
}
