import i18n from "i18next";
import moment from "moment";
import LanguageDetector from "i18next-browser-languagedetector";

import "moment/locale/en-gb";
import "moment/locale/hr";

import {de, en, hr, ru} from "./locales";
import {IS_DEBUG_ENABLED} from "../constants";

const options = {
  interpolation: {
    escapeValue: false  // TIP: Not needed for reactJs
  },

  debug: IS_DEBUG_ENABLED,

  lng: "en",

  resources: {
    hr: {
      common: hr["hr-HR"]
    },
    en: {
      common: en.en
    },
    de: {
      common: de.de
    },
    ru: {
      common: ru.ru
    }
  },

  fallbackLng: "en",

  ns: ["common"],

  defaultNS: "common",

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
};

i18n
.use(LanguageDetector)
.init(options)
.changeLanguage("en", err => {
  moment.locale("en");

  if (err) {
    return console.err("something went wrong loading", err);
  }

  return true;
});

export default i18n;
