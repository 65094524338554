import React, {Component} from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Navigation from "../../components/Navigation";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import DataTable from "../../components/DataTable";
import Row from "../../components/bootstrap/Row";
import Container from "../../components/bootstrap/Content";
import Card from "../../components/Card/Card";

import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";

import {EColors, EInput} from "../../constants";

import {alert} from "../../utils/sweetAlert";
import {isNameValid} from "../../utils/inputUtils";

const MySwal = withReactContent(sweetalert);

class EmployerPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    employers: PropTypes.array.isRequired
  };

  state = {
    isAddClicked: false,
    isEditClicked: false,
    isDeleteClicked: false,
    showEditCard: false,
    isValidFirstName: null,
    isValidLastName: null
  };

  // ------------------------------------------
  // COMMON
  // ------------------------------------------
  resetToDefaultState = () => {
    const {resetEmployer} = this.props;
    resetEmployer();
    this.setState({
      isAddClicked: false,
      isEditClicked: false,
      isDeleteClicked: false,
      showEditCard: false,
      isValidFirstName: null,
      isValidLastName: null
    });
  };

  // ------------------------------------------

  componentDidUpdate = prevProps => {
    const {isAddClicked, isEditClicked, isDeleteClicked} = this.state;
    const {userId, getEmployers} = this.props;

    if (
        isAddClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData &&
        this.props.apiResponse === true
    ) {
      this.resetToDefaultState();
      getEmployers(userId);
    }

    if (
        isEditClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData
    ) {
      this.setState({
        isAddClicked: false,
        isEditClicked: false,
        showEditCard: true,
        isValidFirstName: true,
        isValidLastName: true
      });
    }
    if (
        isDeleteClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData &&
        this.props.apiResponse === true
    ) {
      this.resetToDefaultState();
      getEmployers(userId);
    }
  };

  handleOnClickAdd = () => {
    const {
      t,
      putEmployer,
      postEmployer,
      userId,
      employer_id,
      firstName,
      lastName,
      nickname,
      note
    } = this.props;

    const {showEditCard, isValidFirstName, isValidLastName} = this.state;
    const isFormValid = isValidFirstName && isValidLastName;

    if (isFormValid) {
      const transferData = {
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
        nickname,
        note
      };

      if (showEditCard) {
        transferData.employer_id = employer_id;
        putEmployer(transferData);
      } else {
        postEmployer(transferData);
      }

      this.setState({isAddClicked: true});
    } else {
      alert("warning", t("form-not-valid"));
    }
  };

  handleOnClickEdit = primaryId => {
    this.setState({isEditClicked: true});
    const {getEmployer} = this.props;
    getEmployer(primaryId);
  };

  handleOnClickCancel = () => {
    this.resetToDefaultState();
  };

  handleOnClickDelete = employerId => {
    const {t, deleteEmployer} = this.props;
    MySwal.fire({
      title: t("are-you-sure"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: EColors.DANGER,
      cancelButtonColor: EColors.SECONDARY,
      confirmButtonText: t("yes"),
      cancelButtonText: t("cancel")
    }).then(result => {
      if (result.value) {
        this.setState({isDeleteClicked: true});
        deleteEmployer(employerId);
        MySwal.fire({
          title: t("deleted"),
          type: "success",
          confirmButtonColor: EColors.SECONDARY,
          confirmButtonText: t("close")
        });
      }
    });
  };
  handleInputChange = ({target}) => {
    const {name, value} = target;
    const {setEmployerInfo} = this.props;

    if (name === EInput.EMPLOYER_FIRST_NAME.name) {
      setEmployerInfo({first_name: value});
    } else if (name === EInput.EMPLOYER_LAST_NAME.name) {
      setEmployerInfo({last_name: value});
    } else if (name === EInput.EMPLOYER_NICKNAME.name) {
      setEmployerInfo({nickname: value});
    } else if (name === EInput.EMPLOYER_NOTE.name) {
      setEmployerInfo({note: value});
    }
  };

  render() {
    const {showEditCard, isValidFirstName, isValidLastName} = this.state;
    const {
      t,
      isLoading,
      employers,
      firstName,
      lastName,
      nickname,
      note
    } = this.props;

    return (
        <React.Fragment>
          <Navigation t={t}/>
          <Page>
            <PageHeader
                title={t("employers")}
                breadcrumbs={[{title: t("home"), to: "/home"}]}
            />
            <div className="content">
              <Container fluid>
                <Row>
                  <div className="col-12 col-sm-4">
                    <Card
                        title={showEditCard ? t("edit") : t("create-new")}
                        loading={isLoading}
                    >
                      <Input
                          id={EInput.EMPLOYER_FIRST_NAME.id}
                          name={EInput.EMPLOYER_FIRST_NAME.name}
                          icon={EInput.EMPLOYER_FIRST_NAME.icon}
                          type={EInput.EMPLOYER_FIRST_NAME.type}
                          label={t(EInput.EMPLOYER_FIRST_NAME.label)}
                          placeholder={t(
                              EInput.EMPLOYER_FIRST_NAME.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidFirstName: isNameValid(firstName)
                            });
                          }}
                          required
                          value={firstName}
                          isValid={isValidFirstName}
                      />
                      <Input
                          id={EInput.EMPLOYER_LAST_NAME.id}
                          name={EInput.EMPLOYER_LAST_NAME.name}
                          icon={EInput.EMPLOYER_LAST_NAME.icon}
                          type={EInput.EMPLOYER_LAST_NAME.type}
                          label={t(EInput.EMPLOYER_LAST_NAME.label)}
                          placeholder={t(EInput.EMPLOYER_LAST_NAME.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidLastName: isNameValid(lastName)
                            });
                          }}
                          required
                          value={lastName}
                          isValid={isValidLastName}
                      />
                      <Input
                          id={EInput.EMPLOYER_NICKNAME.id}
                          name={EInput.EMPLOYER_NICKNAME.name}
                          icon={EInput.EMPLOYER_NICKNAME.icon}
                          type={EInput.EMPLOYER_NICKNAME.type}
                          label={t(EInput.EMPLOYER_NICKNAME.label)}
                          placeholder={t(EInput.EMPLOYER_NICKNAME.placeholder)}
                          onChange={this.handleInputChange}
                          value={nickname}
                          required={false}
                          optionalText={t("optional")}
                      />
                      <Input
                          id={EInput.EMPLOYER_NOTE.id}
                          name={EInput.EMPLOYER_NOTE.name}
                          icon={EInput.EMPLOYER_NOTE.icon}
                          type={EInput.EMPLOYER_NOTE.type}
                          label={t(EInput.EMPLOYER_NOTE.label)}
                          placeholder={t(EInput.EMPLOYER_NOTE.placeholder)}
                          onChange={this.handleInputChange}
                          value={note}
                          required={false}
                          optionalText={t("optional")}
                      />
                      <Row>
                        {showEditCard && (
                            <div className="col-6 offset-sm-4 col-sm-4">
                              {showEditCard && (
                                  <Button
                                      type="submit"
                                      color="default"
                                      title={t("cancel")}
                                      onClick={this.handleOnClickCancel}
                                  />
                              )}
                            </div>
                        )}
                        <div
                            className={`${
                                showEditCard
                                    ? "col-6 col-sm-4"
                                    : "offset-6 col-6 offset-sm-8 col-sm-4"
                            }`}
                        >
                          <Button
                              type="submit"
                              title={showEditCard ? t("edit") : t("save")}
                              onClick={this.handleOnClickAdd}
                          />
                        </div>
                      </Row>
                    </Card>
                  </div>
                  <div className="col-12 col-sm-8">
                    <Card title={t("overview")} loading={isLoading}>
                      <DataTable
                          t={t}
                          data={employers}
                          primaryId="employer_id"
                          onClickEdit={this.handleOnClickEdit}
                          onClickDelete={this.handleOnClickDelete}
                      />
                    </Card>
                  </div>
                </Row>
              </Container>
            </div>
          </Page>
          <PageFooter/>
        </React.Fragment>
    );
  }
}

export default translate("common")(EmployerPage);
