import React from "react";
import PropTypes from "prop-types";
import {Radio, RadioGroup} from "react-icheck";

import Row from "../../../components/bootstrap/Row";
import Input from "../../../components/elements/Input";
import EmployerSelect from "../../../components/elements/EmployerSelect";
import CurrencySelect from "../../../components/elements/CurrencySelect";

import {EInput} from "../../../constants";

import {
  isDateValid,
  isPriceValid,
  isTimeValid
} from "../../../utils/inputUtils";

import "icheck/skins/square/green.css";
import "icheck/skins/square/blue.css";
import "icheck/skins/square/red.css";

export default class EditContractModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    employers: PropTypes.array.isRequired,
    currencies: PropTypes.array.isRequired,
    selectedDate: PropTypes.string.isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      employer: props.contract.employer_id,
      currency: props.contract.currency_id,
      startTime: props.contract.start_time,
      finishTime: props.contract.finish_time || "",
      price: props.contract.price,
      date: props.contract.date,
      submit: props.contract.submitted ? true : false,
      action: props.contract.submitted ? "submit" : "active",
      isValidDate: isDateValid(props.contract.date),
      isValidStartTime: isTimeValid(props.contract.start_time),
      isValidFinishTime: props.contract.finish_time
          ? isTimeValid(props.contract.finish_time)
          : false,
      isValidPrice: isPriceValid(props.contract.price)
    };
  }

  setDefaultValue = (value, type) => {
    if (type === "currency") {
      this.setState({currency: value});
    } else if (type === "employer") {
      this.setState({employer: value});
    }
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;

    if (name === EInput.EMPLOYER.name) {
      this.setState({employer: value});
    } else if (name === EInput.CURRENCY.name) {
      this.setState({currency: value});
    } else if (name === EInput.CONTRACT_DATE.name) {
      this.setState({date: value});
    } else if (name === EInput.CONTRACT_START_TIME.name) {
      this.setState({startTime: value});
    } else if (name === EInput.CONTRACT_FINISH_TIME.name) {
      this.setState({finishTime: value});
    } else if (name === EInput.CONTRACT_PRICE.name) {
      this.setState({price: value});
    }
  };

  render() {
    const {t, employers, currencies} = this.props;
    const {
      employer,
      currency,
      startTime,
      finishTime,
      date,
      price,
      action,
      isValidDate,
      isValidStartTime,
      isValidFinishTime,
      isValidPrice
    } = this.state;

    return (
        <div>
          <Input
              id={EInput.CONTRACT_DATE.id}
              name={EInput.CONTRACT_DATE.name}
              icon={EInput.CONTRACT_DATE.icon}
              type={EInput.CONTRACT_DATE.type}
              label={t(EInput.CONTRACT_DATE.label)}
              placeholder={t(EInput.CONTRACT_DATE.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidDate: isDateValid(date)
                });
              }}
              required
              value={date}
              isValid={isValidDate}
          />
          <Input
              id={EInput.CONTRACT_START_TIME.id}
              name={EInput.CONTRACT_START_TIME.name}
              icon={EInput.CONTRACT_START_TIME.icon}
              type={EInput.CONTRACT_START_TIME.type}
              step={EInput.CONTRACT_START_TIME.step}
              label={t(EInput.CONTRACT_START_TIME.label)}
              placeholder={t(EInput.CONTRACT_START_TIME.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidStartTime: isTimeValid(startTime)
                });
              }}
              required
              value={startTime}
              isValid={isValidStartTime}
          />
          <Input
              id={EInput.CONTRACT_FINISH_TIME.id}
              name={EInput.CONTRACT_FINISH_TIME.name}
              icon={EInput.CONTRACT_FINISH_TIME.icon}
              type={EInput.CONTRACT_FINISH_TIME.type}
              step={EInput.CONTRACT_FINISH_TIME.step}
              label={t(EInput.CONTRACT_FINISH_TIME.label)}
              placeholder={t(EInput.CONTRACT_FINISH_TIME.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidFinishTime: isTimeValid(finishTime)
                });
              }}
              required
              value={finishTime}
              isValid={isValidFinishTime}
          />
          <EmployerSelect
              id={EInput.EMPLOYER.id}
              name={EInput.EMPLOYER.name}
              icon={EInput.EMPLOYER.icon}
              label={t(EInput.EMPLOYER.label)}
              onChange={this.handleInputChange}
              setDefaultValue={this.setDefaultValue}
              employers={employers}
              selectedValue={employer}
          />
          <Input
              id={EInput.CONTRACT_PRICE.id}
              name={EInput.CONTRACT_PRICE.name}
              icon={EInput.CONTRACT_PRICE.icon}
              type={EInput.CONTRACT_PRICE.type}
              label={t(EInput.CONTRACT_PRICE.label)}
              placeholder={t(EInput.CONTRACT_PRICE.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidPrice: isPriceValid(price)
                });
              }}
              required
              value={price}
              isValid={isValidPrice}
          />
          <CurrencySelect
              id={EInput.CURRENCY.id}
              name={EInput.CURRENCY.name}
              icon={EInput.CURRENCY.icon}
              label={t(EInput.CURRENCY.label)}
              onChange={this.handleInputChange}
              setDefaultValue={this.setDefaultValue}
              currencies={currencies}
              selectedValue={currency}
          />

          <Row>
            <div className="col-12 text-center">
              <RadioGroup name="radioAction" value={action}>
                <Radio
                    id="active"
                    value="active"
                    radioClass="iradio_square-blue"
                    increaseArea="20%"
                    label={`<span class='mr-4'>${t("active")}</span>`}
                    onClick={() => {
                      this.setState({action: "active"});
                    }}
                />
                <Radio
                    id="submit"
                    value="submit"
                    radioClass="iradio_square-green"
                    increaseArea="20%"
                    label={`<span class='mr-4'>${t("submit")}</span>`}
                    onClick={() => {
                      this.setState({action: "submit"});
                    }}
                />
                <Radio
                    id="delete"
                    value="delete"
                    radioClass="iradio_square-red"
                    increaseArea="20%"
                    label={t("delete")}
                    onClick={() => {
                      this.setState({action: "delete"});
                    }}
                />
              </RadioGroup>
            </div>
          </Row>
        </div>
    );
  }
}
