import contractTypes from "./types";

const initialState = {
  isLoading: false,
  hasFetchedData: false,
  hasFailed: false,
  apiResponse: []
};

export const resetUnsubmittedContracts = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: []
});

export const getUnsubmittedContractsStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getUnsubmittedContractsSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const getUnsubmittedContractsFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  hasFailed: true
});

const reducer = {
  [contractTypes.GET_UNSUBMITTED_CONTRACTS_START]: getUnsubmittedContractsStart,
  [contractTypes.GET_UNSUBMITTED_CONTRACTS_SUCCESS]: getUnsubmittedContractsSuccess,
  [contractTypes.GET_UNSUBMITTED_CONTRACTS_FAILED]: getUnsubmittedContractsFailed,
  [contractTypes.RESET_UNSUBMITTED_CONTRACTS]: resetUnsubmittedContracts
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
