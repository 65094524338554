import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {push} from "react-router-redux";

import {
  postRegister,
  resetRegister,
  setRegisterInfo
} from "../../modules/user/actions";

import RegisterPage from "./component";

const mapStateToProps = ({user}) => ({
  firstname: user.register.firstname,
  lastname: user.register.lastname,
  username: user.register.username,
  email: user.register.email,
  password: user.register.password,
  repeatpassword: user.register.repeatpassword,
  isLoading: user.register.isLoading,
  hasFetchedData: user.register.hasFetched,
  hasError: user.register.hasError
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          resetRegister,
          setRegisterInfo,
          postRegister,
          push
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterPage);
