import moment from "moment";

export function generateMonthNames(numberOfMonths) {
  const labels = [];

  for (let i = numberOfMonths; i >= 1; i = i - 1) {
    labels.push(
        moment()
        .subtract(i, "month")
        .startOf("month")
        .format("MMMM")
    );
  }

  const currentMonth = moment().format("MMMM");
  labels.push(currentMonth);

  return labels;
}

export function sumPricePerMonthPerCurrency(labels, contracts) {
  const perCurrency = contracts.reverse().reduce(function (total, obj) {
    // FIXME - Rename maybe to currency_name
    let key = obj["name"];
    if (!total[key]) {
      total[key] = [];
    }
    total[key].push(obj);
    return total;
  }, {});

  const chartData = [];

  for (var currency in perCurrency) {
    const sumPerMonth = new Map();

    for (let i = 0; i < labels.length; i = i + 1) {
      sumPerMonth.set(labels[i], 0);
    }

    for (let i = 0; i < perCurrency[currency].length; i = i + 1) {
      const key = moment(perCurrency[currency][i].date).format("MMMM");

      sumPerMonth.set(
          key,
          sumPerMonth.get(key) + perCurrency[currency][i].price
      );
    }

    const fixedDecimalPlaces = Object.values(
        Array.from(sumPerMonth.values())
    ).map(function (each_element) {
      return Number(each_element.toFixed(2));
    });

    chartData.push({label: currency, data: fixedDecimalPlaces});
  }

  return chartData;
}

export function calculateDifferenceFromLastMonth(data) {
  let currentMonth = 0;
  let lastMonth = 0;

  data.forEach(element => {
    currentMonth = currentMonth + element["data"][element["data"].length - 1];
    lastMonth = lastMonth + element["data"][element["data"].length - 2];
  });

  const result = Number(((currentMonth - lastMonth) / lastMonth) * 100).toFixed(
      2
  );

  return result;
}

export function calculateCurrentMonthIncome(data) {
  let currentMonth = 0;

  data.forEach(element => {
    currentMonth = currentMonth + element["data"][element["data"].length - 1];
  });

  const result = Number(currentMonth).toFixed(2);
  return result;
}
