import React from "react";
import PropTypes from "prop-types";

export default class Card extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.oneOf([
      "default",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "danger"
    ]),
    maximizable: PropTypes.bool,
    minimizable: PropTypes.bool,
    removeable: PropTypes.bool,
    loading: PropTypes.bool,
    gradient: PropTypes.bool,
    outline: PropTypes.bool,
    noPadding: PropTypes.bool,
    reportTo: PropTypes.string
  };

  static defaultProps = {
    title: "Card Example",
    icon: undefined,
    color: "primary",
    maximizable: true,
    minimizable: true,
    removeable: true,
    loading: undefined,
    gradient: undefined,
    outline: undefined,
    noPadding: undefined,
    reportTo: undefined
  };

  render() {
    const {
      title,
      icon,
      color,
      maximizable,
      minimizable,
      removeable,
      loading,
      gradient,
      outline,
      noPadding,
      reportTo,
      push
    } = this.props;

    const classForCard = gradient
        ? `card bg-gradient-${color}`
        : outline
            ? `card card-${color} card-outline`
            : `card card-${color}`;

    return (
        <div className={classForCard}>
          <div className="card-header">
            <h3 className="card-title">
              {icon && (
                  <span>
                <i className={icon}/> &nbsp;
              </span>
              )}
              {title}
            </h3>
            {(maximizable || minimizable || removeable) && (
                <div className="card-tools">
                  {reportTo && (
                      <button
                          onClick={() => push(reportTo)}
                          type="button"
                          className="btn btn-tool"
                      >
                        <i className="fas fa-external-link-alt"/>
                      </button>
                  )}
                  {maximizable && (
                      <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="maximize"
                      >
                        <i className="fas fa-expand"/>
                      </button>
                  )}
                  {minimizable && (
                      <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                      >
                        <i className="fas fa-minus"/>
                      </button>
                  )}
                  {removeable && (
                      <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="remove"
                      >
                        <i className="fas fa-times"/>
                      </button>
                  )}
                </div>
            )}
          </div>
          <div className={noPadding ? "card-body p-0" : "card-body"}>
            {this.props.children}
          </div>
          {loading && (
              <div className="overlay">
                <i className="fas fa-sync-alt fa-spin"/>
              </div>
          )}
        </div>
    );
  }
}
