export function getDefaultValue(options, selectedValue) {
  if (!selectedValue && options.length > 0) {
    return options[0].value;
  }

  return selectedValue;
}

export function isUsernameValid(value) {
  const isValid = value && value.length > 5;
  return isValid;
}

export function isPasswordValid(value) {
  const isValid = value && value.length > 6;
  return isValid;
}

export function isDateValid(value) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (!value || !value.match(regEx)) {
    return false;
  }

  const d = new Date(value);
  const dNum = d.getTime();

  if (!dNum && dNum !== 0) {
    return false;
  }

  return d.toISOString().slice(0, 10) === value;
}

export function isMinutesValid(value) {
  if (!value) {
    return false;
  }

  const timeArray = value.split(":");

  if (
      timeArray[1] !== "00" &&
      timeArray[1] !== "15" &&
      timeArray[1] !== "30" &&
      timeArray[1] !== "45"
  ) {
    return false;
  }

  return true;
}

export function isTimeValid(value) {
  const isValidTime = value
      && /(^(([0-1][0-9])|([2][0-3])):([0-5][0-9]):([0-5][0-9])$|(^([0-1][0-9])|([2][0-3])):([0-5][0-9])$)/.test(
          value
      );

  const isValidMinutes = isMinutesValid(value);

  return isValidTime && isValidMinutes;
}

export function isPriceValid(value) {
  const isValid = value && /^\d+(\.\d{1,2})?$/.test(value);
  return isValid;
}

export function isTimeSpanValid(startTime, finishTime) {
  const isValid = startTime && finishTime && finishTime > startTime;
  return isValid;
}

export function isCurrencyNameValid(value) {
  const isValid = value && value.length > 3;
  return isValid;
}

export function isCurrencyCodeValid(value) {
  const isValid = value && value.length === 3;
  return isValid;
}

export function isNameValid(value) {
  const isValid = value && value.length > 1;
  return isValid;
}

export function isEmailValid(value) {
  const isValid = value
      && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
      );
  return isValid;
}
