import React from "react";
import ReactDOM from "react-dom";
import {I18nextProvider} from "react-i18next";
// 3rd party lib
import createHistory from "history/createBrowserHistory";
// local lib
import configureStore from "./store/configure-store";
// Components
import App from "./routes/App";
import i18n from "./i18n";
// Facebook cache
import * as serviceWorker from "./serviceWorker";
import './customJquery';
// CSS Imports
// -------------------------------------
import 'jquery/dist/jquery.slim.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js/dist/popper.min.js';
import 'admin-lte/dist/css/adminlte.min.css';
import 'admin-lte/dist/js/adminlte.min';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/index.css";
import "flag-icon-css/css/flag-icon.min.css";

// History and Redux store
const history = createHistory();
const store = configureStore(history);

// Main Code
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App store={store} history={history}/>
    </I18nextProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
