import React from "react";
import PropTypes from "prop-types";

import Input from "../../../components/elements/Input";
import EmployerSelect from "../../../components/elements/EmployerSelect";
import CurrencySelect from "../../../components/elements/CurrencySelect";

import {EInput} from "../../../constants";

import {
  isDateValid,
  isPriceValid,
  isTimeValid
} from "../../../utils/inputUtils";
import Select from "../../../components/elements/Select";

export default class AddContractModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    employers: PropTypes.array.isRequired,
    currencies: PropTypes.array.isRequired,
    selectedDate: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      employer: null,
      currency: null,
      startTime: "",
      price: "",
      date: props.selectedDate || "",
      isValidDate: props.selectedDate !== null ? true : false,
      isValidStartTime: false,
      isValidPrice: false
    };
  }

  setDefaultValue = (value, type) => {
    if (type === "currency") {
      this.setState({currency: value});
    } else if (type === "employer") {
      this.setState({employer: value});
    }
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;

    if (name === EInput.EMPLOYER.name) {
      this.setState({employer: value});
    } else if (name === EInput.CURRENCY.name) {
      this.setState({currency: value});
    } else if (name === EInput.CONTRACT_DATE.name) {
      this.setState({date: value});
    } else if (name === EInput.CONTRACT_START_TIME.name) {
      this.setState({startTime: value});
    } else if (name === EInput.CONTRACT_PRICE.name) {
      this.setState({price: value});
    }
  };

  getOptionsForRepeat = () => {
    const {t} = this.props;
    const options = [];

    options.push({
      value: "noRepeat",
      name: `${t("no-repeat")}`
    });

    options.push({
      value: "everyWeek",
      name: `${t("every-week")}`
    });

    options.push({
      value: "everySecondWeek",
      name: `${t("every-second-week")}`
    });

    return options;
  };

  render() {
    const {t, employers, currencies} = this.props;
    const {
      employer,
      currency,
      startTime,
      date,
      price,
      isValidDate,
      isValidStartTime,
      isValidPrice
    } = this.state;

    const options = this.getOptionsForRepeat();
    return (
        <div>
          <Input
              id={EInput.CONTRACT_DATE.id}
              name={EInput.CONTRACT_DATE.name}
              icon={EInput.CONTRACT_DATE.icon}
              type={EInput.CONTRACT_DATE.type}
              label={t(EInput.CONTRACT_DATE.label)}
              placeholder={t(EInput.CONTRACT_DATE.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidDate: isDateValid(date)
                });
              }}
              required
              value={date}
              isValid={isValidDate}
          />
          <Input
              id={EInput.CONTRACT_START_TIME.id}
              name={EInput.CONTRACT_START_TIME.name}
              icon={EInput.CONTRACT_START_TIME.icon}
              type={EInput.CONTRACT_START_TIME.type}
              step={EInput.CONTRACT_START_TIME.step}
              label={t(EInput.CONTRACT_START_TIME.label)}
              placeholder={t(EInput.CONTRACT_START_TIME.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidStartTime: isTimeValid(startTime)
                });
              }}
              required
              value={startTime}
              isValid={isValidStartTime}
          />
          <EmployerSelect
              id={EInput.EMPLOYER.id}
              name={EInput.EMPLOYER.name}
              icon={EInput.EMPLOYER.icon}
              label={t(EInput.EMPLOYER.label)}
              onChange={this.handleInputChange}
              setDefaultValue={this.setDefaultValue}
              employers={employers}
              selectedValue={employer}
          />
          <Input
              id={EInput.CONTRACT_PRICE.id}
              name={EInput.CONTRACT_PRICE.name}
              icon={EInput.CONTRACT_PRICE.icon}
              type={EInput.CONTRACT_PRICE.type}
              label={t(EInput.CONTRACT_PRICE.label)}
              placeholder={t(EInput.CONTRACT_PRICE.placeholder)}
              onChange={this.handleInputChange}
              onBlur={() => {
                this.setState({
                  isValidPrice: isPriceValid(price)
                });
              }}
              required
              value={price}
              isValid={isValidPrice}
          />
          <CurrencySelect
              id={EInput.CURRENCY.id}
              name={EInput.CURRENCY.name}
              icon={EInput.CURRENCY.icon}
              label={t(EInput.CURRENCY.label)}
              onChange={this.handleInputChange}
              setDefaultValue={this.setDefaultValue}
              currencies={currencies}
              selectedValue={currency}
          />
          <Select
              id={EInput.CONTRACT_REPEAT.id}
              name={EInput.CONTRACT_REPEAT.name}
              icon={EInput.CONTRACT_REPEAT.icon}
              type={EInput.CONTRACT_REPEAT.type}
              label={t(EInput.CONTRACT_REPEAT.label)}
              options={options}
              required
          />
        </div>
    );
  }
}
