import React, {Component} from "react";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Page from "../../components/Page";
import Navigation from "../../components/Navigation";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import DataTable from "../../components/DataTable";
import Card from "../../components/Card/Card";

import Row from "../../components/bootstrap/Row";
import Container from "../../components/bootstrap/Content";

import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";

import {EColors, EInput} from "../../constants";

import {alert} from "../../utils/sweetAlert";
import {isCurrencyCodeValid, isCurrencyNameValid} from "../../utils/inputUtils";

const MySwal = withReactContent(sweetalert);

class CurrencyPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    currencies: PropTypes.array.isRequired
  };

  state = {
    isAddClicked: false,
    isEditClicked: false,
    isDeleteClicked: false,
    showEditCard: false,
    isValidCode: null,
    isValidName: null
  };

  // ------------------------------------------
  // COMMON
  // ------------------------------------------
  resetToDefaultState = () => {
    const {resetCurrency} = this.props;
    resetCurrency();
    this.setState({
      isAddClicked: false,
      isEditClicked: false,
      isDeleteClicked: false,
      showEditCard: false,
      isValidCode: null,
      isValidName: null
    });
  };

  // ------------------------------------------

  componentDidUpdate = prevProps => {
    const {isAddClicked, isEditClicked, isDeleteClicked} = this.state;
    const {userId, getCurrencies} = this.props;

    if (
        isAddClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData &&
        this.props.apiResponse === true
    ) {
      this.resetToDefaultState();
      getCurrencies(userId);
    }

    if (
        isEditClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData
    ) {
      this.setState({
        isAddClicked: false,
        isEditClicked: false,
        showEditCard: true,
        isValidCode: true,
        isValidName: true
      });
    }

    if (
        isDeleteClicked &&
        prevProps.hasFetchedData !== this.props.hasFetchedData &&
        this.props.apiResponse === true
    ) {
      this.resetToDefaultState();
      getCurrencies(userId);
    }
  };

  handleOnClickAdd = () => {
    const {
      t,
      putCurrency,
      postCurrency,
      userId,
      currency_id,
      code,
      name
    } = this.props;
    const {showEditCard, isValidCode, isValidName} = this.state;
    const isFormValid = isValidCode && isValidName;

    if (isFormValid) {
      const transferData = {
        user_id: userId,
        name,
        code
      };

      if (showEditCard) {
        transferData.currency_id = currency_id;
        putCurrency(transferData);
      } else {
        postCurrency(transferData);
      }

      this.setState({isAddClicked: true});
    } else {
      alert("warning", t("form-not-valid"));
    }
  };

  handleOnClickEdit = primaryId => {
    this.setState({isEditClicked: true});
    const {getCurrency} = this.props;
    getCurrency(primaryId);
  };

  handleOnClickCancel = () => {
    this.resetToDefaultState();
  };

  handleOnClickDelete = currencyId => {
    const {t, deleteCurrency} = this.props;
    MySwal.fire({
      title: t("are-you-sure"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: EColors.DANGER,
      cancelButtonColor: EColors.SECONDARY,
      confirmButtonText: t("yes"),
      cancelButtonText: t("cancel")
    }).then(result => {
      if (result.value) {
        this.setState({isDeleteClicked: true});
        deleteCurrency(currencyId);
        MySwal.fire({
          title: t("deleted"),
          type: "success",
          confirmButtonColor: EColors.SECONDARY,
          confirmButtonText: t("close")
        });
      }
    });
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;
    const {setCurrencyInfo} = this.props;

    if (name === EInput.CURRENCY_CODE.name) {
      setCurrencyInfo({code: value});
    } else if (name === EInput.CURRENCY_NAME.name) {
      setCurrencyInfo({name: value});
    }
  };

  render() {
    const {showEditCard, isValidCode, isValidName} = this.state;
    const {t, isLoading, currencies, code, name} = this.props;

    return (
        <React.Fragment>
          <Navigation t={t}/>
          <Page>
            <PageHeader
                title={t("currencies")}
                breadcrumbs={[{title: t("home"), to: "/home"}]}
            />
            <div className="content">
              <Container fluid>
                <Row>
                  <div className="col-12 col-sm-4">
                    <Card
                        title={showEditCard ? t("edit") : t("create-new")}
                        loading={isLoading}
                    >
                      <Input
                          id={EInput.CURRENCY_NAME.id}
                          name={EInput.CURRENCY_NAME.name}
                          icon={EInput.CURRENCY_NAME.icon}
                          type={EInput.CURRENCY_NAME.type}
                          label={t(EInput.CURRENCY_NAME.label)}
                          placeholder={t(EInput.CURRENCY_NAME.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidName: isCurrencyNameValid(name)
                            });
                          }}
                          required
                          value={name}
                          isValid={isValidName}
                      />
                      <Input
                          id={EInput.CURRENCY_CODE.id}
                          name={EInput.CURRENCY_CODE.name}
                          icon={EInput.CURRENCY_CODE.icon}
                          type={EInput.CURRENCY_CODE.type}
                          label={t(EInput.CURRENCY_CODE.label)}
                          placeholder={t(EInput.CURRENCY_CODE.placeholder)}
                          onChange={this.handleInputChange}
                          onBlur={() => {
                            this.setState({
                              isValidCode: isCurrencyCodeValid(code)
                            });
                          }}
                          required
                          value={code}
                          isValid={isValidCode}
                      />
                      <Row>
                        {showEditCard && (
                            <div className="col-6 offset-sm-4 col-sm-4">
                              {showEditCard && (
                                  <Button
                                      type="submit"
                                      color="default"
                                      title={t("cancel")}
                                      onClick={this.handleOnClickCancel}
                                  />
                              )}
                            </div>
                        )}
                        <div
                            className={`${
                                showEditCard
                                    ? "col-6 col-sm-4"
                                    : "offset-6 col-6 offset-sm-8 col-sm-4"
                            }`}
                        >
                          <Button
                              type="submit"
                              title={showEditCard ? t("edit") : t("save")}
                              onClick={this.handleOnClickAdd}
                          />
                        </div>
                      </Row>
                    </Card>
                  </div>
                  <div className="col-12 col-sm-8">
                    <Card title={t("overview")} loading={isLoading}>
                      <DataTable
                          t={t}
                          data={currencies}
                          primaryId="currency_id"
                          onClickEdit={this.handleOnClickEdit}
                          onClickDelete={this.handleOnClickDelete}
                      />
                    </Card>
                  </div>
                </Row>
              </Container>
            </div>
          </Page>
          <PageFooter/>
        </React.Fragment>
    );
  }
}

export default translate("common")(CurrencyPage);
