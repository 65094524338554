import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import Button from "../elements/Button";
import Input from "../elements/Input";

import {HIDDEN_COLUMNS} from "../../constants";

import {replaceUnderscoreWithMinus} from "../../utils/stringUtils";
import {generateRadomString} from "../../utils/generatorUtils";
import TableHeader from "../table/TableHeader";

export default class DataTable extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    primaryId: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired
  };

  static defaultProps = {
    onClickEdit: () => console.debug("onClickEdit"),
    onClickDelete: () => console.debug("onClickDelete")
  };

  state = {
    searchTearm: ""
  };

  componentDidUpdate = () => {
    const {searchTearm} = this.state;
    const term = searchTearm.toLowerCase();

    $("#data-table-body tr").filter(function () {
      $(this).toggle(
          $(this)
          .text()
          .toLowerCase()
          .indexOf(term) > -1
      );

      return true;
    });
  };

  handleSearchInput = ({target}) => {
    const {value} = target;
    this.setState({searchTearm: value});
  };

  generateTableFromData = (data, primaryId) => {
    const {t, onClickEdit, onClickDelete} = this.props;
    const tableHeadValues = [];

    if (!data || data.length === 0) {
      return <div className="text-center">No data</div>;
    }

    tableHeadValues.push("#");

    // For each Property in JSON Object of First element
    // {firstName, lastName....}

    // Add the property to the array for table header
    for (var property in data[0]) {
      // Hide few selected properties
      if (HIDDEN_COLUMNS.includes(property)) {
        continue;
      }

      tableHeadValues.push(t(replaceUnderscoreWithMinus(property)));
    }

    tableHeadValues.push(t("action"));

    const tableBodyValues = [];

    for (let i = 0; i < data.length; i = i + 1) {
      const tableData = [];
      let primaryIdForClick = null;

      tableData.push(
          <th
              scope="row"
              key={generateRadomString()}
              style={{cursor: "pointer", textDecoration: "underline"}}
              className="text-center text-primary"
              onClick={() => onClickEdit(primaryIdForClick)}
          >
            {i + 1}
          </th>
      );

      for (var prop in data[i]) {
        if (prop === primaryId) {
          primaryIdForClick = data[i][prop];
        }

        // Do not show to the user the values for IDS
        if (HIDDEN_COLUMNS.includes(prop)) {
          continue;
        }

        tableData.push(<td key={generateRadomString()}>{data[i][prop]}</td>);
      }

      tableData.push(
          <td key={generateRadomString()} className="text-center">
            <Button
                title={t("delete")}
                color="danger"
                block={false}
                small
                onClick={() => onClickDelete(primaryIdForClick)}
            />
          </td>
      );

      tableBodyValues.push(<tr key={generateRadomString()}>{tableData}</tr>);
    }

    const tableBody = <tbody id="data-table-body">{tableBodyValues}</tbody>;

    const {searchTearm} = this.state;

    const table = (
        <React.Fragment>
          <Input
              label={t("search")}
              // id={EInput.CURRENCY_NAME.id}
              // name={EInput.CURRENCY_NAME.name}
              // icon={EInput.CURRENCY_NAME.icon}
              // type={EInput.CURRENCY_NAME.type}
              // label={t(EInput.CURRENCY_NAME.label)}
              // placeholder={t(EInput.CURRENCY_NAME.placeholder)}
              onChange={this.handleSearchInput}
              required
              value={searchTearm}
          />
          <div className="table-responsive">
            <table
                id="data-table"
                className="table table-sm table-bordered table-hover"
                role="grid"
                aria-describedby="data-table_info"
            >
              <TableHeader data={tableHeadValues}/>
              {tableBody}
            </table>
          </div>
        </React.Fragment>
    );

    return table;
  };

  render() {
    const {data, primaryId} = this.props;

    return <div>{this.generateTableFromData(data, primaryId)}</div>;
  }
}
