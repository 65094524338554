import i18n from "i18next";
import moment from "moment";
// Configuration from Environment
import EUriConfig from "../config";

export const IS_DEBUG_ENABLED = process.env.NODE_ENV !== 'production';

// Constants for API
export const API_URI_AUTH = `${EUriConfig.URI_AUTH}/api/v2`;

// Constants for validation
export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

// Charts - Number of Months to report
export const CHARTS_MAX_MONTHS = 6;

export const HIDDEN_COLUMNS = [
  "employer_id",
  "user_id",
  "currency_id",
  "address_id",
  "contact_id",
  "contract_id",
  "activity_id",
  "active",
  "main"
];

export const DND_EVENTS = "external-events";
export const DND_EVENT = "external-event";

export const IS_VALID_CLASS = "is-valid";

export const DATE_FORMAT = "YYYY-MM-DD";

/**
 * Enum for navigation
 * @readonly
 * @enum {string}
 */
export const EColors = Object.freeze({
  DEFAULT: "#6c757d",
  PRIMARY: "#007bff",
  SECONDARY: "#6c757d",
  SUCCESS: "#28a745",
  INFO: "#17a2b8",
  WARNING: "#ffc107",
  DANGER: "#dc3545",
  WHITE: "#fff",
  BLACK: "#000"
});

/**
 * Enum for navigation
 * @readonly
 * @enum {{to: string, title: string, icon: string}}
 */
export const ENavigation = Object.freeze({
  HOME: {
    to: "/home",
    title: "dashboard",
    icon: "fas fa-tachometer-alt"
  },
  CURRENCIES: {
    to: "/currencies",
    title: "currencies",
    icon: "fas fa-euro-sign"
  },
  EMPLOYERS: {
    to: "/employers",
    title: "employers",
    icon: "fas fa-users"
  },
  CONTRACTS: {
    to: "/contracts",
    title: "contracts",
    icon: "fas fa-file-signature"
  }
});

/**
 * Enum for languages
 * @readonly
 * @enum {{title: string, icon: string, onClick: function}}
 */
export const ELanguages = Object.freeze({
  CROATIAN: {
    title: "croatian",
    icon: "flag-icon flag-icon-hr",
    onClick: () => {
      i18n.changeLanguage("hr", err => {
        moment.locale("hr");

        if (err) {
          return console.err("something went wrong loading", err);
        }

        return true;
      });
    }
  },
  ENGLISH: {
    title: "english",
    icon: "flag-icon flag-icon-gb",
    onClick: () => {
      i18n.changeLanguage("en", err => {
        moment.locale("en");

        if (err) {
          return console.err("something went wrong loading", err);
        }

        return true;
      });
    }
  },
  RUSSIAN: {
    title: "russian",
    icon: "flag-icon flag-icon-ru",
    onClick: () => {
      i18n.changeLanguage("ru", err => {
        moment.locale("ru");

        if (err) {
          return console.err("something went wrong loading", err);
        }

        return true;
      });
    }
  },
  GERMAN: {
    title: "german",
    icon: "flag-icon flag-icon-de",
    onClick: () => {
      i18n.changeLanguage("de", err => {
        moment.locale("de");

        if (err) {
          return console.err("something went wrong loading", err);
        }

        return true;
      });
    }
  },
});

/**
 * Enum for inputs
 * @readonly
 * @enum {{id: string, name: string, icon: string, label: string, placeholder: string}}
 */
export const EInput = Object.freeze({
  USERNAME: {
    id: "username",
    name: "username",
    placeholder: "username",
    type: "text"
  },
  PASSWORD: {
    id: "password",
    name: "password",
    placeholder: "password",
    type: "password"
  },
  REPEAT_PASSWORD: {
    id: "repeat-password",
    name: "repeat-password",
    placeholder: "repeat-password",
    type: "password"
  },
  FIRST_NAME: {
    id: "first-name",
    name: "first-name",
    placeholder: "first-name",
    type: "text"
  },
  LAST_NAME: {
    id: "last-name",
    name: "last-name",
    placeholder: "last-name",
    type: "text"
  },
  EMAIL: {
    id: "email",
    name: "email",
    placeholder: "email",
    type: "email"
  },
  CURRENCY_NAME: {
    id: "currency-name",
    name: "currency-name",
    icon: "fas fa-tag primary-icon",
    label: "currency-name",
    placeholder: "currency-name",
    type: "text"
  },
  CURRENCY_CODE: {
    id: "currency-code",
    name: "currency-code",
    icon: "fas fa-euro-sign primary-icon",
    label: "currency-code",
    placeholder: "currency-code",
    type: "text"
  },
  EMPLOYER_FIRST_NAME: {
    id: "first-name",
    name: "first-name",
    icon: "fas fa-signature primary-icon",
    label: "first-name",
    placeholder: "first-name",
    type: "text"
  },
  EMPLOYER_LAST_NAME: {
    id: "last-name",
    name: "last-name",
    icon: "fas fa-signature primary-icon",
    label: "last-name",
    placeholder: "last-name",
    type: "text"
  },
  EMPLOYER_NICKNAME: {
    id: "nickname",
    name: "nickname",
    icon: "fas fa-signature primary-icon",
    label: "nickname",
    placeholder: "nickname",
    type: "text"
  },
  EMPLOYER_NOTE: {
    id: "note",
    name: "note",
    icon: "fas fa-sticky-note primary-icon",
    label: "note",
    placeholder: "note",
    type: "text"
  },
  CURRENCY: {
    id: "currency",
    name: "currency",
    icon: "fas fa-money-bill-alt primary-icon",
    label: "currency",
    placeholder: "currency"
  },
  EMPLOYER: {
    id: "employer",
    name: "employer",
    icon: "fas fa-users primary-icon",
    label: "employer",
    placeholder: "employer"
  },
  CONTRACT_DATE: {
    id: "contract-date",
    name: "contract-date",
    icon: "fas fa-calendar-alt primary-icon",
    label: "date",
    placeholder: "date",
    type: "date"
  },
  CONTRACT_START_TIME: {
    id: "contract-start-time",
    name: "contract-start-time",
    icon: "fas fa-hourglass-start primary-icon",
    label: "start-time",
    placeholder: "start-time",
    type: "time",
    step: "900"
  },
  CONTRACT_FINISH_TIME: {
    id: "contract-finish-time",
    name: "contract-finish-time",
    icon: "fas fa-hourglass-end primary-icon",
    label: "finish-time",
    placeholder: "finish-time",
    type: "time",
    step: "900"
  },
  CONTRACT_PRICE: {
    id: "price",
    name: "price",
    icon: "fas fa-coins primary-icon",
    label: "price",
    placeholder: "price",
    type: "number"
  },
  CONTRACT_REPEAT: {
    id: "repeat-in-intervals",
    name: "repeat-in-intervals",
    icon: "fas fa-redo primary-icon",
    label: "repeat-in-intervals",
    placeholder: "repeat-in-intervals",
    type: "checkbox"
  }
});
