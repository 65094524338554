import {call, put, takeLatest} from "redux-saga/effects";
import {deleteRequest} from "../../utils/axios-wrapper";
import currenciesTypes from "../types";

import {
  deleteCurrencyFailed,
  deleteCurrencyStart,
  deleteCurrencySuccess
} from "../actions";

export const callToPostCurrency = currencyId =>
    deleteRequest({
      route: `/currencies/${currencyId}`
    });

export function* deleteCurrency({currencyId}) {
  try {
    yield put(deleteCurrencyStart());
    const apiResponse = yield call(callToPostCurrency, currencyId);
    yield put(deleteCurrencySuccess(apiResponse.data));
  } catch (error) {
    yield put(deleteCurrencyFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* postCurrencySaga() {
  yield takeLatest(currenciesTypes.DELETE_CURRENCY, deleteCurrency);
}
