import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import unsubmittedContractTypes from "../types";

import {
  getUnsubmittedContractsFailed,
  getUnsubmittedContractsStart,
  getUnsubmittedContractsSuccess
} from "../actions";

export const callToGetUnsubmittedContracts = userId =>
    get({
      route: `/unsubmitted-contracts/${userId}`
    });

export function* getUnsubmittedContracts({userId}) {
  try {
    yield put(getUnsubmittedContractsStart());
    const apiResponse = yield call(callToGetUnsubmittedContracts, userId);
    yield put(getUnsubmittedContractsSuccess(apiResponse.data));
  } catch (error) {
    yield put(getUnsubmittedContractsFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getUnsubmittedContractsSaga() {
  yield takeLatest(
      unsubmittedContractTypes.GET_UNSUBMITTED_CONTRACTS,
      getUnsubmittedContracts
  );
}
