import React from "react";
import PropTypes from "prop-types";

import Label from "../Label";
import DragAndDropElement from "../DragAndDropElement";

import {DND_EVENTS} from "../../../constants";
import {generateRadomString} from "../../../utils/generatorUtils";

export default class DragAndDropElements extends React.Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    optionalText: PropTypes.string.isRequired,
    employers: PropTypes.arrayOf(
        PropTypes.shape({
          employer_id: PropTypes.number,
          user_id: PropTypes.number,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          nickname: PropTypes.string,
          note: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
          ]),
          active: PropTypes.number
        })
    ).isRequired
  };

  generateDnDEvents = employers => {
    const dndEvents = [];

    employers.forEach(element => {
      dndEvents.push(
          <DragAndDropElement
              key={generateRadomString()}
              dataEvent={{employer_id: element.employer_id}}
              title={`${element.last_name} ${element.first_name}`}
          />
      );
    });

    return dndEvents;
  };

  render = () => {
    const {employers, icon, label, optionalText} = this.props;

    return (
        <React.Fragment>
          <Label icon={icon} text={label} optionalText={optionalText}/>
          <div id={DND_EVENTS}>{this.generateDnDEvents(employers)}</div>
        </React.Fragment>
    );
  };
}
