import React, {Component} from "react";
import {translate} from "react-i18next";

import ALink from "../../components/elements/ALink";
import Row from "../../components/bootstrap/Row";
import Button from "../../components/elements/Button";
import Input from "../../components/elements/Input";

import {EInput} from "../../constants";

import {alert} from "../../utils/sweetAlert";
import {isPasswordValid, isUsernameValid} from "../../utils/inputUtils";

class LoginPage extends Component {
  state = {
    isButtonClicked: null,
  };

  componentDidUpdate = prevprops => {
    const {isButtonClicked} = this.state;
    const {t} = this.props;

    if (prevprops.hasError !== this.props.hasError && isButtonClicked) {
      alert("error", t("error-login"));
      this.props.resetLogin();
      this.setState({
        isButtonClicked: null,
      });
    }
  };

  handleInputChange = ({target}) => {
    const {name, value} = target;
    const {setLoginInfo} = this.props;

    if (name === EInput.USERNAME.name) {
      setLoginInfo({username: value});
    } else if (name === EInput.PASSWORD.name) {
      setLoginInfo({password: value});
    }
  };

  handleSignIn = () => {
    const {t, postLogin, username, password} = this.props;
    const isFormValid = username.length > 5 && password.length > 6;

    if (isFormValid) {
      const transferData = {
        username,
        password
      };

      this.setState({isButtonClicked: true});
      postLogin(transferData);
    } else {
      alert("warning", t("form-not-valid"));
    }
  };

  render() {
    const {isValidUsername, isValidPassword} = this.state;
    const {t, isLoading, username, password} = this.props;

    return (
        <div className="login-page">
          <div className="login-box">
            <div className="login-logo">
              <b>Work</b>Book
            </div>
            <div className="card ">
              <div className="card-body login-card-body">
                <p className="login-box-msg">{t("sign-in-to-start")}</p>
                <form>
                  <Input
                      id={EInput.USERNAME.id}
                      name={EInput.USERNAME.name}
                      type={EInput.USERNAME.type}
                      placeholder={t(EInput.USERNAME.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidUsername: isUsernameValid(username)
                        });
                      }}
                      required
                      value={username}
                      isValid={isValidUsername}
                  />
                  <Input
                      id={EInput.PASSWORD.id}
                      name={EInput.PASSWORD.name}
                      type={EInput.PASSWORD.type}
                      placeholder={t(EInput.PASSWORD.placeholder)}
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.setState({
                          isValidPassword: isPasswordValid(password)
                        });
                      }}
                      required
                      value={password}
                      isValid={isValidPassword}
                  />
                  <Row>
                    <div className="col-8"/>
                    <div className="col-4">
                      <Button
                          type="submit"
                          title={t("sign-in")}
                          onClick={this.handleSignIn}
                      />
                    </div>
                  </Row>
                </form>
                <p className="mb-1">
                  <ALink to="/reset-password" title={t("forgot-password")}/>
                </p>
                <p className="mb-0">
                  <ALink to="/register" title={t("register-membership")}/>
                </p>
              </div>
              {isLoading && (
                  <div className="overlay">
                    <i className="fas fa-sync-alt fa-spin"/>
                  </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default translate("common")(LoginPage);
