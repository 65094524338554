import React from "react";
import PropTypes from "prop-types";

export default class ALink extends React.Component {
  static propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    push: PropTypes.func
  };

  static defaultProps = {
    to: "/example-url",
    title: "Example ALink"
  };

  render() {
    const {to, title, push} = this.props;

    return (
        <button className="a-link" type="button" onClick={() => push(to)}>
          {title}
        </button>
    );
  }
}
