import contractTypes from "./types";

const initialState = {
  isLoading: false,
  hasFetchedData: false,
  apiResponse: null,
  contractIdForSubmit: null,
  contracts: [],
  filteredContracts: [],
  contract: {}
};

export const resetContracts = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: null,
  contractIdForSubmit: null,
  contracts: [],
  filteredContracts: [],
  contract: {}
});

export const resetContract = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: null,
  contract: {}
});

export const resetApiResponse = state => ({
  ...state,
  apiResponse: null
});

export const setContractInfo = (state, {newContractInfo}) => ({
  ...state,
  contract: {
    ...state.contract,
    ...newContractInfo
  }
});

export const putSubmitContractStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const putSubmitContractSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const putSubmitContractFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getContractsStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getContractsSuccess = (state, {contracts}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  contracts
});

export const getContractsFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getContractStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getContractSuccess = (state, {contract}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  contract
});

export const getContractFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const postContractStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const postContractSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const postContractFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const putContractStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const putContractSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const putContractFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const deleteContractStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const deleteContractSuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const deleteContractFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

const reducer = {
  [contractTypes.RESET_CONTRACTS]: resetContracts,
  [contractTypes.RESET_CONTRACT]: resetContract,
  [contractTypes.RESET_API_RESPONSE]: resetApiResponse,
  [contractTypes.SET_CONTRACT_INFO]: setContractInfo,
  [contractTypes.PUT_SUBMIT_CONTRACT_START]: putSubmitContractStart,
  [contractTypes.PUT_SUBMIT_CONTRACT_SUCCESS]: putSubmitContractSuccess,
  [contractTypes.PUT_SUBMIT_CONTRACT_FAILED]: putSubmitContractFailed,
  [contractTypes.GET_CONTRACTS_START]: getContractsStart,
  [contractTypes.GET_CONTRACTS_SUCCESS]: getContractsSuccess,
  [contractTypes.GET_CONTRACTS_FAILED]: getContractsFailed,
  [contractTypes.GET_CONTRACT_START]: getContractStart,
  [contractTypes.GET_CONTRACT_SUCCESS]: getContractSuccess,
  [contractTypes.GET_CONTRACT_FAILED]: getContractFailed,
  [contractTypes.POST_CONTRACT_START]: postContractStart,
  [contractTypes.POST_CONTRACT_SUCCESS]: postContractSuccess,
  [contractTypes.POST_CONTRACT_FAILED]: postContractFailed,
  [contractTypes.PUT_CONTRACT_START]: putContractStart,
  [contractTypes.PUT_CONTRACT_SUCCESS]: putContractSuccess,
  [contractTypes.PUT_CONTRACT_FAILED]: putContractFailed,
  [contractTypes.DELETE_CONTRACT_START]: deleteContractStart,
  [contractTypes.DELETE_CONTRACT_SUCCESS]: deleteContractSuccess,
  [contractTypes.DELETE_CONTRACT_FAILED]: deleteContractFailed
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
