import React, {Component} from "react";
import {translate} from "react-i18next";
import sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Navigation from "../../components/Navigation";
import Page from "../../components/Page";
import PageHeader from "../../components/PageHeader";
import PageFooter from "../../components/PageFooter";
import Row from "../../components/bootstrap/Row";
import SmallBox from "../../components/SmallBox";
import Calendar from "../../components/Calendar";
import Container from "../../components/bootstrap/Content";
import AddContractModal from "./AddContractModal";
import EditContractModal from "./EditContractModal";
import DashboardReport from "../../components/DashboardReport";

import {alert} from "../../utils/sweetAlert";
import {CHARTS_MAX_MONTHS, EColors, EInput} from "../../constants";
import {isModalDataValid} from "../../utils/validateModals";
import {
  calculateTodoContractsForToday,
  extractSubmittedContracts
} from "../../utils/contractUtils";
import {
  calculateCurrentMonthIncome,
  calculateDifferenceFromLastMonth,
  generateMonthNames,
  sumPricePerMonthPerCurrency
} from "../../utils/chartUtils";

const MySwal = withReactContent(sweetalert);

class DashboardPage extends Component {
  state = {
    isAddClicked: false,
    isEditClicked: false
  };

  componentDidUpdate = async prevProps => {
    const {isAddClicked, isEditClicked} = this.state;
    const {t, userId, getContracts} = this.props;

    // -----------------
    // Case when user clicks ADD new event on empty space
    // -----------------
    if (
        isAddClicked &&
        prevProps.hasFetchedDataContracts !== this.props.hasFetchedDataContracts
    ) {
      alert("success", t("successfully-executed"));

      getContracts(userId);
      this.setState({
        isAddClicked: false
      });
    }

    // -----------------
    // Case when user clicks edit event
    // -----------------
    if (
        isEditClicked &&
        prevProps.hasFetchedDataContracts !== this.props.hasFetchedDataContracts
    ) {
      this.setState({
        isEditClicked: false
      });

      const {t, employers, currencies, contract} = this.props;
      const {value: formValues} = await MySwal.fire({
        title: t("edit-contract"),
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: t("cancel"),
        confirmButtonText: t("save"),
        confirmButtonColor: EColors.PRIMARY,
        cancelButtonColor: EColors.SECONDARY,
        html: (
            <EditContractModal
                t={t}
                employers={employers}
                currencies={currencies}
                contract={contract}
            />
        ),
        preConfirm: () => {
          const employerEl = document.getElementById(EInput.EMPLOYER.id);
          const employerId = employerEl.options[employerEl.selectedIndex].value;
          const currencyEl = document.getElementById(EInput.CURRENCY.id);
          const currencyId = currencyEl.options[currencyEl.selectedIndex].value;

          return [
            document.getElementById(EInput.CONTRACT_DATE.id).value,
            document.getElementById(EInput.CONTRACT_START_TIME.id).value,
            document.getElementById(EInput.CONTRACT_FINISH_TIME.id).value,
            employerId,
            currencyId,
            document.getElementById(EInput.CONTRACT_PRICE.id).value,
            document.getElementById("active").checked,
            document.getElementById("submit").checked,
            document.getElementById("delete").checked
          ];
        }
      });

      if (formValues !== undefined && formValues !== null) {
        const date = formValues[0];
        const startTime = formValues[1];
        const finishTime = formValues[2];
        const employer = formValues[3];
        const currency = formValues[4];
        const price = formValues[5];

        const isActive = formValues[6];
        const isSubmit = formValues[7];
        const isDelete = formValues[8];

        if (isDelete) {
          const {deleteContract} = this.props;
          deleteContract(contract.contract_id);

          this.setState({
            isAddClicked: true
          });
        } else if (
            (isActive || isSubmit) &&
            isModalDataValid(
                t,
                date,
                startTime,
                finishTime,
                employer,
                currency,
                price,
                isSubmit
            )
        ) {
          const {userId, putContract} = this.props;

          const transferData = {
            contract_id: contract.contract_id,
            user_id: userId,
            employer_id: employer,
            currency_id: currency,
            date,
            start_time: startTime,
            finish_time: finishTime !== "" ? finishTime : null,
            price,
            submitted: isSubmit ? 1 : 0
          };

          putContract(transferData);
        }

        this.setState({
          isAddClicked: true
        });
      }
    }
  };

  onDateClick = async selectedDate => {
    const {t, employers, currencies} = this.props;

    const {value: formValues} = await MySwal.fire({
      title: t("new-contract"),
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonText: t("save"),
      confirmButtonColor: EColors.PRIMARY,
      cancelButtonColor: EColors.SECONDARY,
      html: (
          <AddContractModal
              t={t}
              employers={employers}
              currencies={currencies}
              selectedDate={selectedDate}
          />
      ),
      preConfirm: () => {
        const employerEl = document.getElementById(EInput.EMPLOYER.id);
        const employerId = employerEl.options[employerEl.selectedIndex].value;
        const currencyEl = document.getElementById(EInput.CURRENCY.id);
        const currencyId = currencyEl.options[currencyEl.selectedIndex].value;

        return [
          document.getElementById(EInput.CONTRACT_DATE.id).value,
          document.getElementById(EInput.CONTRACT_START_TIME.id).value,
          employerId,
          currencyId,
          document.getElementById(EInput.CONTRACT_PRICE.id).value
        ];
      }
    });

    if (formValues !== undefined) {
      const date = formValues[0];
      const startTime = formValues[1];
      const employer = formValues[2];
      const currency = formValues[3];
      const price = formValues[4];

      if (
          isModalDataValid(
              t,
              date,
              startTime,
              null,
              employer,
              currency,
              price,
              false
          )
      ) {
        const {userId, postContract} = this.props;

        const transferData = {
          user_id: userId,
          employer_id: employer,
          currency_id: currency,
          date,
          start_time: startTime,
          price
        };

        postContract(transferData);
        this.setState({isAddClicked: true});
      }
    }
  };

  onEventClick = async eventProps => {
    const {getContract} = this.props;
    getContract(eventProps.contractId);
    this.setState({isEditClicked: true});
  };

  render() {
    const {t, isLoadingContracts, contracts} = this.props;

    // -----------------------------
    // TODO: Refactor from render
    // -----------------------------
    const numOfContractsForToday = calculateTodoContractsForToday(contracts);
    const submittedContracts = extractSubmittedContracts(
        CHARTS_MAX_MONTHS - 1,
        contracts
    );
    const labels = generateMonthNames(CHARTS_MAX_MONTHS - 1);
    const data = sumPricePerMonthPerCurrency(labels, submittedContracts);
    const lastMonthDifference = calculateDifferenceFromLastMonth(data);
    const currentMonthIncome = calculateCurrentMonthIncome(data);

    return (
        <React.Fragment>
          <Navigation t={t}/>
          <Page>
            <PageHeader
                title={t("dashboard")}
                breadcrumbs={[{title: t("home"), to: "/home"}]}
            />
            <div className="content">
              <Container fluid>
                <Row>
                  <div className="col-6">
                    <SmallBox
                        color={
                          numOfContractsForToday.length === 0 ? "success"
                              : "info"
                        }
                        text={t("todays-contracts")}
                        icon="fas fa-play-circle"
                        value={numOfContractsForToday.length}
                        loading={isLoadingContracts}
                        moreInfo={t("more-info")}
                    />
                  </div>
                  <div className="col-6">
                    <SmallBox
                        color="info"
                        text={t("realized-income")}
                        icon="fas fa-money-bill-alt"
                        value={currentMonthIncome}
                        loading={isLoadingContracts}
                        moreInfo={t("more-info")}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-12">
                    <DashboardReport
                        title={t("realized-income")}
                        reporting={t("submitted-contracts")}
                        reportingValue={submittedContracts.length}
                        loading={isLoadingContracts}
                        reportPercent={lastMonthDifference}
                        labels={labels}
                        data={data}
                        reportSince={t("since-last-month")}
                        noDataText={t("no-data-text")}
                    />
                  </div>
                </Row>
                <Calendar
                    title={t("calendar")}
                    t={t}
                    onDateClick={this.onDateClick}
                    onEventClick={this.onEventClick}
                    data={contracts}
                    loading={isLoadingContracts}
                />
              </Container>
            </div>
          </Page>
          <PageFooter/>
        </React.Fragment>
    );
  }
}

export default translate("common")(DashboardPage);
