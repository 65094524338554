import React from "react";
import PropTypes from "prop-types";

import {generateRadomString} from "../../../utils/generatorUtils";

// TODO Placeholder for Select
// <option value="" disabled selected hidden>Please Choose...</option>

export default class Select extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        })
    ).isRequired
  };

  static defaultProps = {
    label: undefined,
    icon: undefined,
    id: undefined,
    name: undefined,
    onChange: () => {
      console.debug("Input change...");
    },
    value: undefined,
    disabled: undefined
  };

  render() {
    const {
      label,
      icon,
      id,
      name,
      onChange,
      value,
      disabled,
      options
    } = this.props;

    console.debug(options);
    return (
        <div className="form-group text-left">
          {label && (
              <label htmlFor={id}>
                {icon && (
                    <span>
                <i className={icon}/>
                      &nbsp;
              </span>
                )}
                {label}
              </label>
          )}

          <select
              name={name}
              className="form-control"
              id={id}
              onChange={onChange}
              value={value}
              disabled={disabled}
          >
            {options.map(element => (
                <option key={generateRadomString()} value={element.value}>
                  {element.name}
                </option>
            ))}
          </select>
        </div>
    );
  }
}
