/**
 * Enum for navigation
 * @readonly
 * @enum {{to: string, title: string, icon: string}}
 */
export const EUriConfig = Object.freeze({
  URI_AUTH: process.env.REACT_APP_API || "http://localhost:9501",
});

export default EUriConfig;
