import React from "react";

export default class NavbarLinks extends React.Component {
  render() {
    return (
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link" data-card-widget="pushmenu" href="#">
              <i className="fas fa-bars"/>
            </span>
          </li>
          {this.props.children}
        </ul>
    );
  }
}
