export function capitalizeFirstLetter(stringValue) {
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

export function replaceUnderscoreWithSpace(stringValue) {
  return stringValue.split("_").join(" ");
}

export function replaceUnderscoreWithMinus(stringValue) {
  return stringValue.split("_").join("-");
}

export function removeFirstLetter(stringValue) {
  return stringValue.slice(1);
}
