import currenciesTypes from "./types";

const initialState = {
  isLoading: false,
  hasFetchedData: false,
  currencies: [],
  currency: {},
  apiResponse: {}
};

export const resetCurrencies = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  currencies: [],
  filteredCurrencies: [],
  currency: {},
  apiResponse: {}
});

export const resetCurrency = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: false,
  apiResponse: {},
  currency: {}
});

export const setCurrencyInfo = (state, {newInfo}) => ({
  ...state,
  currency: {
    ...state.currency,
    ...newInfo
  }
});

export const getCurrencyStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getCurrencySuccess = (state, {currency}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  currency
});

export const getCurrencyFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const getCurrenciesStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const getCurrenciesSuccess = (state, {currencies}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  currencies
});

export const getCurrenciesFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const postCurrencyStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const postCurrencySuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const postCurrencyFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const deleteCurrencyStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const deleteCurrencySuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const deleteCurrencyFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

export const putCurrencyStart = state => ({
  ...state,
  isLoading: true,
  hasFetchedData: false
});

export const putCurrencySuccess = (state, {apiResponse}) => ({
  ...state,
  isLoading: false,
  hasFetchedData: true,
  apiResponse
});

export const putCurrencyFailed = state => ({
  ...state,
  isLoading: false,
  hasFetchedData: true
});

const reducer = {
  [currenciesTypes.RESET_CURRENCIES]: resetCurrencies,
  [currenciesTypes.RESET_CURRENCY]: resetCurrency,
  [currenciesTypes.SET_CURRENCY_INFO]: setCurrencyInfo,
  [currenciesTypes.GET_CURRENCY_START]: getCurrencyStart,
  [currenciesTypes.GET_CURRENCY_SUCCESS]: getCurrencySuccess,
  [currenciesTypes.GET_CURRENCY_FAILED]: getCurrencyFailed,
  [currenciesTypes.GET_CURRENCIES_START]: getCurrenciesStart,
  [currenciesTypes.GET_CURRENCIES_SUCCESS]: getCurrenciesSuccess,
  [currenciesTypes.GET_CURRENCIES_FAILED]: getCurrenciesFailed,
  [currenciesTypes.POST_CURRENCY_START]: postCurrencyStart,
  [currenciesTypes.POST_CURRENCY_SUCCESS]: postCurrencySuccess,
  [currenciesTypes.POST_CURRENCY_FAILED]: postCurrencyFailed,
  [currenciesTypes.DELETE_CURRENCY_START]: deleteCurrencyStart,
  [currenciesTypes.DELETE_CURRENCY_SUCCESS]: deleteCurrencySuccess,
  [currenciesTypes.DELETE_CURRENCY_FAILED]: deleteCurrencyFailed,
  [currenciesTypes.PUT_CURRENCY_START]: putCurrencyStart,
  [currenciesTypes.PUT_CURRENCY_SUCCESS]: putCurrencySuccess,
  [currenciesTypes.PUT_CURRENCY_FAILED]: putCurrencyFailed
};

export default (state = initialState, action = {}) =>
    reducer[action.type] ? reducer[action.type](state, action) : state;
