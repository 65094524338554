import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import currenciesTypes from "../types";

import {
  getCurrenciesFailed,
  getCurrenciesStart,
  getCurrenciesSuccess
} from "../actions";

export const callToGetCurrencies = userId =>
    get({
      route: `/currencies/user/${userId}`
    });

export function* getCurrencies({userId}) {
  try {
    yield put(getCurrenciesStart());
    const apiResponse = yield call(callToGetCurrencies, userId);
    yield put(getCurrenciesSuccess(apiResponse.data));
  } catch (error) {
    yield put(getCurrenciesFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getCurrenciesSaga() {
  yield takeLatest(currenciesTypes.GET_CURRENCIES, getCurrencies);
}
