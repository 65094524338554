import React from "react";
import PropTypes from "prop-types";

import LineChart from "../elements/LineChart";
import Card from "../Card";

import {removeFirstLetter} from "../../utils/stringUtils";

export default class DashboardReport extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    title: PropTypes.string,
    reportSince: PropTypes.string,
    reportPercent: PropTypes.string,
    reporting: PropTypes.string,
    reportingValue: PropTypes.number,
    viewReportTo: PropTypes.string,
    loading: PropTypes.bool,
    labels: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number)
    ]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          data: PropTypes.arrayOf(PropTypes.number).isRequired
        })
    ),
    legends: PropTypes.array,
    noDataText: PropTypes.string
  };

  static defaultProps = {
    title: "examples",
    reportSince: "Since last month",
    reportPercent: "21",
    isPositive: false,
    reporting: "Visitors Over Time",
    reportingValue: "820",
    viewReportTo: "/report",
    loading: undefined,
    labels: undefined,
    data: undefined,
    legends: null,
    noDataText: "Not enough data"
  };

  render() {
    const {
      title,
      reportSince,
      reportPercent,
      reporting,
      reportingValue,
      viewReportTo,
      loading,
      labels,
      data,
      legends,
      noDataText
    } = this.props;

    const isPositive = reportPercent >= 0 ? true : false;

    return (
        <Card
            reportTo={viewReportTo}
            title={title}
            outline
            color={`${isPositive ? "success" : "danger"}`}
            loading={loading}
        >
          <div className="d-flex">
            <p className="d-flex flex-column">
              <span className="text-bold text-lg">{reportingValue}</span>
              <span>{reporting}</span>
            </p>
            <p className="ml-auto d-flex flex-column text-right">
              {reportPercent === "NaN" ? (
                  <span>{noDataText}</span>
              ) : (
                  <span>
                <span
                    className={`text-bold text-lg text-${
                        isPositive ? "success" : "danger"
                    }`}
                >
                  <i className={`fas fa-arrow-${isPositive ? "up" : "down"}`}/>
                  {isPositive
                      ? reportPercent
                      : removeFirstLetter(reportPercent)}
                  &#37;
                </span>
                <span>{reportSince}</span>
              </span>
              )}
            </p>
          </div>
          <div className={`position-relative ${legends ? " mb-2" : ""}`}>
            <LineChart labels={labels} data={data}/>
          </div>
          {legends && (
              <div className="d-flex flex-row justify-content-end">
            <span className="mr-2">
              <i className="fas fa-square text-primary"/> This Week
            </span>
                <span>
              <i className="fas fa-square text-gray"/> Last Week
            </span>
              </div>
          )}
        </Card>
    );
  }
}
