import {isDateValid, isPriceValid, isTimeValid} from "./inputUtils";

import {alert} from "./sweetAlert";

export function isModalDataValid(
    t,
    date,
    startTime,
    finishTime,
    employer,
    currency,
    price,
    isSubmit
) {
  if (!isDateValid(date)) {
    alert("warning", t("date-is-not-valid"));
    return false;
  }

  if (!isTimeValid(startTime)) {
    alert("warning", t("start-time-is-not-valid"));
    return false;
  }

  if (isSubmit && !isTimeValid(finishTime)) {
    alert("warning", t("finish-time-is-not-valid"));
    return false;
  }

  if (!employer) {
    alert("warning", t("employer-is-not-valid"));
    return false;
  }

  if (!currency) {
    alert("warning", t("currency-is-not-valid"));
    return false;
  }

  if (!isPriceValid(price)) {
    alert("warning", t("price-is-not-valid"));
    return false;
  }

  return true;
}
