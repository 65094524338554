import React from "react";
import PropTypes from "prop-types";
import Label from "../Label";

export default class Input extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.bool,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    optionalText: PropTypes.string
  };

  static defaultProps = {
    name: undefined,
    label: undefined,
    icon: undefined,
    type: "text",
    id: undefined,
    placeholder: undefined,
    autoComplete: undefined,
    isValid: undefined,
    onChange: () => {
      console.debug("On change...");
    },
    onBlur: () => {
      console.debug("On blur...");
    },
    onFocus: () => {
      console.debug("On focus...");
    },
    value: "",
    disabled: undefined,
    required: undefined,
    optionalText: undefined
  };

  render() {
    const {
      name,
      label,
      icon,
      type,
      id,
      placeholder,
      autoComplete,
      onChange,
      onBlur,
      onFocus,
      value,
      disabled,
      required,
      optionalText
    } = this.props;

    let classForInput = "form-control";

    return (
        <div className="form-group text-left">
          {label && (
              <Label id={id} text={label} icon={icon}
                     optionalText={optionalText}/>
          )}

          <input
              name={name}
              type={type}
              className={classForInput}
              id={id}
              placeholder={placeholder}
              autoComplete={autoComplete}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              value={value}
              disabled={disabled}
              required={required}
          />
        </div>
    );
  }
}
