import employersTypes from "./types";

export const resetEmployers = () => ({
  type: employersTypes.RESET_EMPLOYERS
});

export const resetEmployer = () => ({
  type: employersTypes.RESET_EMPLOYER
});

export const setEmployerInfo = newEmployerInfo => ({
  type: employersTypes.SET_EMPLOYER_INFO,
  newEmployerInfo
});

export const putSubmitEmployerStart = () => ({
  type: employersTypes.PUT_SUBMIT_EMPLOYER_START
});

export const putSubmitEmployer = (userId, dataRequest) => ({
  type: employersTypes.PUT_SUBMIT_EMPLOYER,
  userId,
  dataRequest
});

export const putSubmitEmployerSuccess = apiResponse => ({
  type: employersTypes.PUT_SUBMIT_EMPLOYER_SUCCESS,
  apiResponse
});

export const putSubmitEmployerFailed = () => ({
  type: employersTypes.PUT_SUBMIT_EMPLOYER_FAILED
});

export const getEmployerStart = () => ({
  type: employersTypes.GET_EMPLOYER_START
});

export const getEmployer = employerId => ({
  type: employersTypes.GET_EMPLOYER,
  employerId
});

export const getEmployerSuccess = employer => ({
  type: employersTypes.GET_EMPLOYER_SUCCESS,
  employer
});

export const getEmployerFailed = () => ({
  type: employersTypes.GET_EMPLOYER_FAILED
});

export const getEmployersStart = () => ({
  type: employersTypes.GET_EMPLOYERS_START
});

export const getEmployers = userId => ({
  type: employersTypes.GET_EMPLOYERS,
  userId
});

export const getEmployersSuccess = employers => ({
  type: employersTypes.GET_EMPLOYERS_SUCCESS,
  employers
});

export const getEmployersFailed = () => ({
  type: employersTypes.GET_EMPLOYERS_FAILED
});

export const putEmployerStart = () => ({
  type: employersTypes.PUT_EMPLOYER_START
});

export const putEmployer = dataRequest => ({
  type: employersTypes.PUT_EMPLOYER,
  dataRequest
});

export const putEmployerSuccess = apiResponse => ({
  type: employersTypes.PUT_EMPLOYER_SUCCESS,
  apiResponse
});

export const putEmployerFailed = () => ({
  type: employersTypes.PUT_EMPLOYER_FAILED
});

export const postEmployerStart = () => ({
  type: employersTypes.POST_EMPLOYER_START
});

export const postEmployer = dataRequest => ({
  type: employersTypes.POST_EMPLOYER,
  dataRequest
});

export const postEmployerSuccess = apiResponse => ({
  type: employersTypes.POST_EMPLOYER_SUCCESS,
  apiResponse
});

export const postEmployerFailed = () => ({
  type: employersTypes.POST_EMPLOYER_FAILED
});

export const deleteEmployerStart = () => ({
  type: employersTypes.DELETE_EMPLOYER_START
});

export const deleteEmployer = employerId => ({
  type: employersTypes.DELETE_EMPLOYER,
  employerId
});

export const deleteEmployerSuccess = apiResponse => ({
  type: employersTypes.DELETE_EMPLOYER_SUCCESS,
  apiResponse
});

export const deleteEmployerFailed = () => ({
  type: employersTypes.DELETE_EMPLOYER_FAILED
});
