import {call, put, takeLatest} from "redux-saga/effects";
import {get} from "../../utils/axios-wrapper";
import contractTypes from "../types";

import {
  getContractFailed,
  getContractStart,
  getContractSuccess
} from "../actions";

export const callToSubmitContract = contractId =>
    get({
      route: `/contract/${contractId}`
    });

export function* getContract({contractId}) {
  try {
    yield put(getContractStart());
    const apiResponse = yield call(callToSubmitContract, contractId);
    yield put(getContractSuccess(apiResponse.data));
  } catch (error) {
    yield put(getContractFailed(error));
    console.error(JSON.stringify(error));
  }
}

export default function* getContractSaga() {
  yield takeLatest(contractTypes.GET_CONTRACT, getContract);
}
