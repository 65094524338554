import {connect} from "react-redux";
import {push} from "react-router-redux";
import {bindActionCreators} from "redux";

import AppComponent from "./component";

import {getContracts} from "../../modules/contracts/actions";
import {getCurrencies} from "../../modules/currencies/actions";
import {getEmployers} from "../../modules/employers/actions";

const mapStateToProps = ({user}) => ({
  accessToken: user.authUser.accessToken,
  userCheckComplete: user.authUser.checkComplete,
  userId: user.authUser.userId
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          push,
          getEmployers,
          getCurrencies,
          getContracts
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppComponent);
